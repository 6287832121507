import i18n from 'i18n-js';

i18n.translations = {
    en: {
        login: 'Login',
        logout: 'Logout',
        account: 'Account',
        password: 'Password',
        loggingIn: 'Logging In',
        loading: 'Loading...',
        typeHereToSearch: 'Type here to search',
        searchCustomerOrder: 'Search order',
        subTotal: 'Sub-Total',
        total: 'Total',
        tax: 'Tax',
        new: 'New',
        checkout: 'Checkout',
        'PICK UP': 'PICK UP',
        'DELIVERY': 'DELIVERY',
        'DINE IN': 'DINE IN',
        itemDetail: 'Item Detail',
        unitPrice: 'Unit Price',
        lotPrice: 'Lot Price',
        sellByLot: 'Sell by lot of',
        itemNumber: 'Item #',
        size: 'Size',
        pcscs: 'pc/cs',
        totalInCart: 'Total in cart',
        updateCart: 'Update Cart',
        soldOut: 'Sold Out',
        tableNumber: 'Table Number',
        findCustomer: 'Find Customer',
        customer: 'Customer',
        name: 'Name',
        namePlaceholder: 'John Doe',
        additionalNote: 'Additional Note',
        additionalNotePlaceholder: 'Put customer order instruction here',
        phoneNumber: 'Phone Number',
        id: "Id",
        email: "Email",
        address: 'Customer Address',
        addressPlaceholder: '123 Ave',
        addressTwo: 'Unit Number',
        addressTwoPlaceholder: '#1',
        city: 'City',
        cityPlaceholder: 'Washington',
        state: 'State',
        statePlaceholder: 'DC',
        zipcode: 'Zipcode',
        zipcodePlaceholder: '11111',
        deliveryInstruction: 'Delivery Instruction',
        deliveryInstructionPlaceholder: 'Put customer delivery note here',
        printOrder: 'Print Order',
        printInvoice: 'Print Invoice',
        printReceipt: 'Print Receipt',
        printFullReceipt: 'Print Full Page Receipt',
        saveOrder: 'Save Order',
        language: 'English/中文',
        errorLogin: 'Error logging in, try typing the username and password again',
        cantFindOrder: 'Cannot find order',
        cantFindCustomer: 'Cannot find customer',
        saveOrderFirst: 'Save the order before printing',
        orderInProgress: "There's an order in progress, cannot search",
        orderSubmitted: "Order submitted successfully",
        orderForbidden: "Order submitted forbidden",
        orderError: "Order submitted error",
        clearOrder: "Are you sure you want to clear the order?",
        scanMode: 'Scan Mode',
        scanText: "Click here and start scanning",
        customerPaid: "Customer Paid Amount",
        tipAmount: "Tip Amount",
        extraFees: "Fee Amount",
        change: "Give Change",
        orderMinimum: 'Minimum purchase amount',
        adjustQty: 'Enter a new quantity',
        adjustPrice: 'Enter a new price',
        noCustomerInOrder: 'No customer associated in order',
        empty: 'Empty'
    },
    zh: {
        login: '登入',
        logout: '退出',
        account: '账户',
        password: '密码',
        loggingIn: '登入中',
        loading: '加载中',
        typeHereToSearch: '在此处输入以进行搜索',
        searchCustomerOrder: '搜索订单',
        subTotal: '小记',
        total: '合计',
        tax: '税',
        new: '新单',
        checkout: '结账',
        'PICK UP': '外卖',
        'DELIVERY': '送餐',
        'DINE IN': '堂吃',
        itemDetail: '商品详情',
        unitPrice: '单价',
        lotPrice: '批价',
        sellByLot: '数量出售',
        itemNumber: '商品号码',
        size: '大小',
        pcscs: '件/批',
        totalInCart: '购物车中的总数',
        updateCart: '更新购物车',
        soldOut: '卖完了',
        tableNumber: '桌号',
        findCustomer: '寻找客户',
        customer: '客户',
        name: '名子',
        namePlaceholder: '张三',
        additionalNote: '订单指示',
        additionalNotePlaceholder: '将客户订单附加说明写在这里',
        phoneNumber: '电话号码',
        id: "号码",
        email: "电邮",
        address: '地址',
        addressPlaceholder: '123 大道',
        city: '城市',
        cityPlaceholder: '华盛顿市',
        state: '州府',
        statePlaceholder: 'DC',
        zip: '区号',
        zipcodePlaceholder: '11111',
        deliveryInstruction: '送货指示',
        deliveryInstructionPlaceholder: '将客户送货指示写在这里',
        printOrder: '打印订单',
        printInvoice: '打印账单',
        printReceipt: '打印发票',
        printFullReceipt: '打印整页收据',
        saveOrder: '保存订单',
        language: '中文/English',
        errorLogin: '无法登入, 尝试重新输入用户和密码',
        cantFindOrder: '找不到订单',
        cantFindCustomer: '找不到客户',
        saveOrderFirst: '先保存订单才能打印',
        orderInProgress: "有一个订单正在处理中，无法搜索",
        orderSubmitted: "订单提交成功",
        orderForbidden: "订单提交被禁止",
        orderError: "订单提交错误",
        clearOrder: "您确定要清除订单吗",
        scanMode: "扫描模式",
        scanText: "单击此处然后开始扫描",
        customerPaid: "客户支付金额",
        tipAmount: "小费金额",
        extraFees: "额外费用",
        change: "找钱",
        orderMinimum: '最低购买金额',
        adjustQty: '输入一个新的数量',
        adjustPrice: '输入一个新的价格',
        noCustomerInOrder: '订单中没有关联客户',
        empty: '清空'
    },
    es: {
        login: 'Login'
    },
};
i18n.fallbacks = true;

export const translator = (locale) => {
    i18n.locale = locale
    return i18n;
};
