import AsyncStorage from '@react-native-async-storage/async-storage';
import {
    ACCOUNT_LOCATION_ID,
    ACCOUNT_PASSWORD,
    ACCOUNT_USERNAME,
    LOCALE,
    SERVER_URL,
    ACCESS_TOKEN,
    AUTH_TYPE
} from './Constants';

export const storeData = async (key, value) => {
    try {
        const jsonValue = JSON.stringify(value);
        await AsyncStorage.setItem(key, jsonValue);
    } catch (e) {
        // saving error
        console.log(e);
    }
}

export const getData = async (key) => {
    try {
        const jsonValue = await AsyncStorage.getItem(key)
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch(e) {
        // error reading value
    }
}

export const removeValue = async (key) => {
    try {
        await AsyncStorage.removeItem(key)
    } catch(e) {
    }
}

export const getServerUrl = async () => {
    //return 'http://localhost:8000/index.php';
    return '/index.php';
};

export const getAccountUsername = async () => {
    return await getData(ACCOUNT_USERNAME);
};

export const getAccountPassword = async () => {
    return await getData(ACCOUNT_PASSWORD);
};

export const getAccountLocationId = async () => {
    return await getData(ACCOUNT_LOCATION_ID);
};

export const getAccessToken = async () => {
    return await getData(ACCESS_TOKEN);
};

export const getAuthType = async () => {
    return await getData(AUTH_TYPE);
};

export const getLocale = async() => {
    return await getData(LOCALE);
}
