import React, {Component} from 'react';
import {Dimensions, Modal, View, ActivityIndicator} from 'react-native';
import {Text} from 'react-native-elements';
import StateStore from '../lib/StateStore';
import {GRAY, TRANSLUCENT} from "../lib/Constants";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default class LoadingModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showLoading: this.props.showLoading,
            loadingText: this.props.loadingText
        };

        this.stateStore = new StateStore(this);
    }

    showModal(visible, text, delay) {
        if (delay) {
            setTimeout(() => {
                this.stateStore.updateState([
                    {key: 'showLoading', value: visible},
                    {key: 'loadingText', value: text},
                ]);
            }, delay)
        } else {
            this.stateStore.updateState([
                {key: 'showLoading', value: visible},
                {key: 'loadingText', value: text},
            ]);
        }
    }

    render() {
        return (
            <Modal
                transparent={true}
                visible={this.state.showLoading}
                style={{
                    width: windowWidth,
                    height: windowHeight,
                    position: 'absolute',
                    borderColor: '#00000000',
                    display: this.state.showLoading ? 'flex' : 'none',
                    zIndex: 100
                }}
            >
                <View style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    backgroundColor: TRANSLUCENT,
                }}>
                    <ActivityIndicator size="large" color={GRAY} />
                    <Text style={{
                        width: '100%',
                        textAlign: 'center',
                        fontSize: 20,
                    }}>{this.state.loadingText}</Text>
                </View>
            </Modal>
        );
    }
}
