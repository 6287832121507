import React, {Component} from 'react';
import {Button, Icon, Text} from 'react-native-elements';
import {Col, Grid, Row} from 'react-native-easy-grid';
import {GRAY, LIGHT_GRAY, RED} from "../lib/Constants";

export default class FooterComponent extends Component {

    constructor(props) {
        super(props);

        let tabs = [
            {name: 'Order', title: 'Take Order', iconName: "shopping-basket", iconType: 'font-awesome-5', badgeCount: 0},
            {name: 'Sales', title: 'Sales', iconName: "chart-line", iconType: 'font-awesome-5', badgeCount: 0},
            {name: 'Menu', title: 'Menu', iconName: "map", iconType: 'font-awesome-5', badgeCount: 0},
            // {name: 'Setting', title: 'Settings', iconName: "cog", iconType: 'FontAwesome5', badgeCount: 0},
        ];

        //[{"name": "Sales", "count": 10}]
        if (Array.isArray(this.props.badgeCounts)) {
            this.props.badgeCounts.forEach((badge, i) => {
                tabs.forEach((tab, j) => {
                    if (tab.name === badge.name) {
                        tab.badgeCount = badge.count;
                    }
                });
            });
        }

        this.state = {
            activeTabName: this.props.activeTabName,
            tabs: tabs
        };
    }

    navigateToTab(tabName) {
        if (this.state.activeTabName !== tabName) {
            this.props.navigation.navigate(tabName);
        }
    }

    updateBadge(tabBadgeName, tabBadgeCount) {
        let thatState = {...this.state};
        thatState.tabs.forEach((tab, index) => {
            if (tab.name === tabBadgeName) {
                tab.badgeCount = tabBadgeCount;
            }
        });

        this.setState({...thatState});
    }

    updateState(mapArray, callback = (prevState, props) => {
    }) {
        let thatState = {...this.state};

        mapArray.forEach((map, index) => {
            thatState[map.key] = map.value;
        });

        this.setState({...thatState}, (prevState, props) => {
            callback(prevState, props);
        });
    }

    render() {
        return (
            <Row style={{backgroundColor: LIGHT_GRAY}}>
                {
                    this.state.tabs.map((tab, index) => {
                        return <Col style={{
                            backgroundColor: this.state.activeTabName === tab.name ? LIGHT_GRAY : 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                                    onPress={() => {
                                        this.navigateToTab(tab.name)
                                    }} key={index}>
                            <Icon name={tab.iconName} type={tab.iconType}/>
                            <Text style={{
                                width: '100%',
                                textAlign: 'center'
                            }}>{tab.title}</Text>
                        </Col>
                    })
                }
            </Row>
        );
    }
}
