import React, {Component} from 'react';
import {ImageBackground, Platform, StyleSheet, View} from 'react-native';
import {Button, Input, Text} from 'react-native-elements';
import {Col, Grid, Row} from 'react-native-easy-grid';
import {getAccountUsername, getLocale, storeData,} from './lib/Storage';
import {authenticate, metaData} from './lib/Network';
import {ACCESS_TOKEN, ACCOUNT_LOCATION_ID, ACCOUNT_USERNAME, AUTH_TYPE, GRAY, LIGHT_GRAY} from './lib/Constants';
import StateStore from "./lib/StateStore";
import LoadingModal from "./Components/LoadingModal";
import {windowHeight, windowWidth} from "./lib/Helper";
import {translator} from "./lib/Translator";

const defaultFont = 'Roboto';

export default class LoginScreen extends Component {

    constructor(props) {
        super(props);

        //default params
        let predefinedLocation = null;

        if (Platform.OS === 'web') {
            //http://localhost:19006/?authType=business&locationId=2000

            const urlParams = new URLSearchParams(window.location.search);
            predefinedLocation = urlParams.get('locationId') ? urlParams.get('locationId') : predefinedLocation;
        }

        this.state = {
            showLoading: false,
            accountUsername: '',
            accountPassword: '',
            accountLocationId: '',
            serverUrl: '',
            meta_data: {
                "order_types": [],
                "order_status": [],
                "payment_methods": [],
                "catalog_status": [],
                "locations": []
            },
            selectedLocation: null,
            i18n: translator('en'),
            predefinedLocation: predefinedLocation
        };

        this.stateStore = new StateStore(this);
    }

    async componentDidMount() {
        await this.loadMetaData(() => {
            this.loadingModal.showModal(false, "");

            if (this.state.predefinedLocation) {
                this.selectLocation(this.state.predefinedLocation);
            } else if (this.state.meta_data?.locations?.length === 1) {
                this.selectLocation(this.state.meta_data.locations[0].id);
            }
        });

        let accountUsername = await getAccountUsername();
        let locale = await getLocale();

        this.stateStore.updateState([
            {key: 'accountUsername', value: accountUsername},
            {key: 'i18n', value: translator(locale)},
        ]);
    }

    async loadMetaData(callback = null) {
        metaData().then(response => {
            storeData('meta_data', response);
            this.stateStore.updateState([
                {key: 'meta_data', value: response},
                {key: 'serverStatus', value: true},
            ], (prevState, props) => {
                if (callback) {
                    callback(prevState, props);
                }
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    selectLocation(locationId) {
        let found = false;
        if (this.state.meta_data?.locations) {
            let allLocations = this.state.meta_data.locations;
            allLocations.forEach(location => {
                if (location.id == locationId) {
                    this.stateStore.updateSingleState('selectedLocation', location);
                    found = true;
                }
            });
        }
        if (!found) {
            console.log('Location not found ' + locationId);
        }
    }

    onValueChange(value) {
        this.stateStore.updateSingleState('accountUsername', value);
    }

    async login() {
        this.loadingModal.showModal(true, this.state.i18n.t('loggingIn'));

        let response = await authenticate(this.state.accountUsername, this.state.accountPassword, this.state.selectedLocation.id)

        this.loadingModal.showModal(false, '');

        if (response && response.success) {
            await storeData(ACCOUNT_USERNAME, this.state.accountUsername);
            await storeData(ACCOUNT_LOCATION_ID, this.state.selectedLocation.id);
            await storeData(AUTH_TYPE, response.message.user_type);
            await storeData(ACCESS_TOKEN, response.message.access_token);

            this.stateStore.updateSingleState('accountPassword', '');
            this.props.navigation.navigate('POS');
        } else {
            alert(this.state.i18n.t('errorLogin'));
            console.log(response);
        }
    }

    render() {
        return (
            <Grid style={{
                height: windowHeight,
                maxHeight: windowHeight,
                width: windowWidth,
                maxWidth: windowWidth,
                backgroundColor: LIGHT_GRAY
            }} dataSet={{qingfeng: 'yang'}}>
                <Col size={4}>
                    <ImageBackground source={{uri: this.state.selectedLocation?.login_background_image_url}}
                                     style={styles.image}/>
                </Col>
                <Col size={6}>
                    <ImageBackground source={require('../assets/login-2.jpg')} style={styles.image}>
                        <Text
                            style={styles.businessNameText}>{this.state.selectedLocation ? this.state.selectedLocation.name : 'Shun Business System'}</Text>
                        <Text
                            style={styles.addressText}>{this.state.selectedLocation ?
                            this.state.selectedLocation.address_1 + ' ' + this.state.selectedLocation.address_2 + ', '+
                            this.state.selectedLocation.zip + ' - '+this.state.selectedLocation.phone_1: ''}
                        </Text>
                        <View style={{width: '50%', marginLeft: '25%', marginTop: 30}}>
                            <Input label={this.state.i18n.t("account")}
                                   labelStyle={styles.whiteLabel}
                                   inputContainerStyle={styles.roundedInput}
                                   leftIcon={{type: 'feather', name: 'user', iconStyle: styles.inputIcon}}
                                   value={this.state.accountUsername} autoCapitalize={'none'}
                                   autoCompleteType={'off'}
                                   autoCorrect={false} secureTextEntry={false} onChangeText={(term) => {
                                this.stateStore.updateSingleState('accountUsername', term);
                            }}/>
                            <Input label={this.state.i18n.t("password")}
                                   labelStyle={styles.whiteLabel}
                                   inputContainerStyle={styles.roundedInput}
                                   leftIcon={{type: 'feather', name: 'lock', iconStyle: styles.inputIcon}}
                                   value={this.state.accountPassword} autoCapitalize={'none'}
                                   autoCompleteType={'off'}
                                   autoCorrect={false} secureTextEntry={true} onChangeText={(term) => {
                                this.stateStore.updateSingleState('accountPassword', term);
                            }}/>
                            <Button title={this.state.i18n.t('login')} onPress={this.login.bind(this)}
                                    style={{width: '60%', marginLeft: '20%', marginTop: 20, height: 80}}/>
                            <View style={{borderBottomWidth: 1, borderBottomColor: LIGHT_GRAY, marginBottom: 20}}/>
                            <Row>
                                <Col>
                                    <Text style={{fontSize: 30, fontWeight: 'bold', textAlign: 'center', width: '100%'}}>
                                        Product Catalog
                                    </Text>
                                    <Button title={'Browse'} onPress={() => {
                                        this.props.navigation.navigate('Catalog');
                                    }} style={{width: '60%', marginLeft: '20%', marginTop: 20, height: 80}}/>
                                </Col>
                            </Row>
                        </View>
                        <Row style={{position: 'fixed', bottom: 10, marginLeft: 10}}>
                            <Text style={{width: '100%', textAlign: 'right'}}>
                                Powered by Shun POS -
                                All Rights Reserved &copy; {new Date().getFullYear()}
                            </Text>
                        </Row>
                    </ImageBackground>
                </Col>

                <LoadingModal ref={child => {
                    this.loadingModal = child;
                }} showLoading={true}/>
            </Grid>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
    },
    image: {
        flex: 1,
        resizeMode: 'cover',
    },
    businessNameText: {
        fontSize: 42,
        width: '50%',
        marginLeft: '25%',
        fontWeight: 'bold',
        textAlign: 'center',
        // backgroundColor: '#00000060',
        marginTop: 100
    },
    addressText: {
        fontSize: 20,
        width: '70%',
        marginLeft: '15%',
        fontWeight: 'bold',
        textAlign: 'center',
        // backgroundColor: '#00000060',
    },
    roundedInput: {
        borderWidth: 1,
        borderRadius: 30
    },
    inputIcon: {
        marginLeft: 5,
        marginRight: 5
    },
    whiteLabel: {
        fontSize: 20,
    },
    calendarText: {
        fontSize: 50,
        lineHeight: 80,
        textAlign: 'center',
        backgroundColor: 'white',
        borderColor: GRAY,
        borderWidth: 1,
        margin: 2
    }
});
