//30 max

import {sprintf} from 'sprintf-js';
import Mustache from 'mustache';

const receiptTemplate = `
<CB>{{location.name}}</CB>
<CB>{{location.address_1}} {{address_2}}</CB>
<CB>{{location.city}} {{location.state}} {{location.zip}}</CB>
<CB>{{location.phone}}</CB>
{{{order.date}}}
{{{order.id}}}

{{meta.header}}
{{#order.items}}
{{item}}
{{/order.items}}

{{order.subTotal}}
{{order.tax}}
{{order.total}}

{{order.tender}}
{{order.change}}
{{order.transaction_id}}

{{meta.extraLine1}}
{{meta.extraLine2}}
{{meta.extraLine3}}
{{meta.extraLine4}}
{{meta.extraLine5}}

<CB>Thank You</CB>

`;

const testPageTemplate = `

{{{date}}}
{{status}}

`;

const formatDate = (currentdate) => {
    return (currentdate.getMonth()+1)  + "/"
        + currentdate.getDate() + "/"
        + currentdate.getFullYear() + " "
        + currentdate.getHours() + ":"
        + currentdate.getMinutes()
};

export const formatDateSuffix = function(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
}

var d = new Date();
var e = formatDate(d);

const generateReceipt = (data) => {
    let location = {
        name: "Osaki Japanese",
        address_1: '123 Fake St',
        address_2: '',
        city: 'Philadelphia',
        state: 'PA',
        zip: '19111',
        phone: '(111)-111-1111',
    };

    let meta = {
        header: sprintf("%' -20s", 'Name') + sprintf("%' 3s", 'Qty') + sprintf("%' 7s", 'Price')
    };

    let order = {
        items: [],
        date: sprintf("%' -5s", 'Date') + sprintf("%' 25s", this.formatDate(new Date())),
        id: sprintf("%' -10s", 'Order #') + sprintf("%' 20s", "1234")
    };

    let items = [
        'Yummy Yummy Sushi Roll Special', "Sweet Tempura", 'Miso Soup', "Party Tray"
    ]

    items.forEach((itemName, index) => {

        if (itemName.length < 20) {
            order.items.push({'item': sprintf("%' -20s", itemName)
                    + sprintf("%' 3s", 10)
                    + sprintf("%' 7s", 999.990.toFixed(2))});
        }
        if (itemName.length > 20) {
            order.items.push({'item': sprintf("%'#-30s", 'Yummy Yummy Sushi Roll Special')});
            order.items.push({'item': sprintf("%' 23s", 10)
                    + sprintf("%' 7s", 999.990.toFixed(2))});
        }
    });

    order.subTotal = sprintf("%' -15s", 'Subtotal') + sprintf("%' 15s", "$"+99.990.toFixed(2));
    order.tax = sprintf("%' -15s", 'Tax (8%)') + sprintf("%' 15s", "$"+99.990.toFixed(2));
    order.total = sprintf("%' -15s", 'Total') + sprintf("%' 15s", "$"+9999.990.toFixed(2));

    order.tender = sprintf("%' -10s", 'Cash') + sprintf("%' 20s", "$"+10000.0.toFixed(2));
    order.change = sprintf("%' -10s", 'Change') + sprintf("%' 20s", "$"+1.0.toFixed(2));
    order.transaction_id = sprintf("%' -10s", 'Ref #') + sprintf("%' 20s", "abc123");

    data = {location: location, meta: meta, order: order};

    return Mustache.render(receiptTemplate, data)
}

const generateOrder = (data) => {

    let order = {
        items: [],
        date: sprintf("%' -5s", 'Date') + sprintf("%' 25s", this.formatDate(new Date())),
        id: sprintf("%' -10s", 'Order #') + sprintf("%' 20s", "1234")
    };

    let items = [
        'Yummy Yummy Sushi Roll Special', "Sweet Tempura", 'Miso Soup', "Party Tray"
    ]

    items.forEach((itemName, index) => {

        if (itemName.length < 20) {
            order.items.push({'item': sprintf("%' -20s", itemName)
                    + sprintf("%' 3s", 10)
                    + sprintf("%' 7s", 999.990.toFixed(2))});
        }
        if (itemName.length > 20) {
            order.items.push({'item': sprintf("%'#-30s", 'Yummy Yummy Sushi Roll Special')});
            order.items.push({'item': sprintf("%' 23s", 10)
                    + sprintf("%' 7s", 999.990.toFixed(2))});
        }
    });

    order.subTotal = sprintf("%' -15s", 'Subtotal') + sprintf("%' 15s", "$"+99.990.toFixed(2));
    order.tax = sprintf("%' -15s", 'Tax (8%)') + sprintf("%' 15s", "$"+99.990.toFixed(2));
    order.total = sprintf("%' -15s", 'Total') + sprintf("%' 15s", "$"+9999.990.toFixed(2));

    order.tender = sprintf("%' -10s", 'Cash') + sprintf("%' 20s", "$"+10000.0.toFixed(2));
    order.change = sprintf("%' -10s", 'Change') + sprintf("%' 20s", "$"+1.0.toFixed(2));
    order.transaction_id = sprintf("%' -10s", 'Ref #') + sprintf("%' 20s", "abc123");

    data = {location: location, meta: meta, order: order};

    return Mustache.render(receiptTemplate, data)
}

const generateInvoice = ``;

const generateTestPage = () => {
    let data = {
        date: formatDate(new Date()),
        status: "Printer works"
    };
    return Mustache.render(testPageTemplate, data);
}

export {generateReceipt, generateOrder, generateTestPage}
