import React, {Component} from 'react';
import {
    Alert,
    Dimensions,
    Modal,
    ScrollView,
    StyleSheet,
    Switch,
    TouchableOpacity,
    View,
    Image,
    TextInput,
    Platform
} from 'react-native';
import {
    ListItem,
    Card,
    Text,
    Button,
    Icon,
    SearchBar,
    ButtonGroup,
    Input,
    Header,
    Divider
} from 'react-native-elements';
import {Col, Grid, Row} from 'react-native-easy-grid';
import {WebView} from 'react-native-webview';
import {sprintf} from 'sprintf-js';
import {SwipeListView} from 'react-native-swipe-list-view';
import {
    getAccountLocationId,
    getAccountPassword,
    getAccountUsername,
    getData,
    getServerUrl, removeValue,
    storeData,
} from './lib/Storage';
import {generateOrder, generateReceipt} from './lib/Receipt';
import {
    POLLING_FREQUENCY,
    TODAY_STR,
    CASH,
    CREDIT_CARD,
    GIFT_CARD,
    DINE_IN,
    TAKEOUT,
    DELIVERY,
    UNPAID,
    CANCELLED, SYNCING_FREQUENCY, RED, LIGHT_GRAY, GRAY, TRANSPARENT, GREEN, BLUE, PROCESSING,
} from './lib/Constants';
import {getFullMenu, saveOrder} from './lib/Network';
import {blankOrder} from './lib/Helper';
import StateStore from './lib/StateStore';
import HeaderComponent from "./Components/HeaderComponent";
import FooterComponent from "./Components/FooterComponent";
import LoadingModal from "./Components/LoadingModal";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
const defaultFont = 'Arial';

const leftColWidth = windowWidth * .15;
const centerColWidth = windowWidth * .60;
const rightColWidth = windowWidth - leftColWidth - centerColWidth;

const orderTypes = [DINE_IN, DELIVERY, TAKEOUT];
const paymentTypes = [CASH, CREDIT_CARD, GIFT_CARD];

export default class OrderScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderModalVisible: false,
            editOrderModalVisible: false,
            full_menu: [],
            current_category: {items: []},
            current_order: blankOrder(),
            search_term: '',
            search_items: [],
            serverStatus: false,
            location: {
                tax_rate: 0.08
            },
            event_queue: [],
            listViewData: Array(100)
                .fill("")
                .map((_, i) => ({key: `${i}`, text: `item #${i}`}))
        };

        this.stateStore = new StateStore(this);
    }

    async componentDidMount() {
        let sessionId = await getSessionId();

        this.stateStore.updateState([
            {key: 'accountLocationId', value: await getAccountLocationId()},
            {key: 'sessionId', value: sessionId},
        ]);

        await this.loadFullMenu();

        this.loadingModal.showModal(false, "");

        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            let routeParams = this.props.route.params;
            if (routeParams && routeParams.hasOwnProperty('current_order')) {
                try {
                    let current_order = routeParams['current_order']
                    if (current_order) {
                        current_order = JSON.parse(current_order);

                        if (current_order.hasOwnProperty('customer')) {
                            current_order.customer = JSON.parse(current_order.customer);
                        }
                    }

                    console.log(current_order);
                    console.log(this.state.current_order);
                    //this.stateStore.updateSingleState('current_order', current_order);
                } catch (e) {
                }
            }
        });

        // this.pollingTimer = setInterval(() => {
        //     polling().then(response => {
        //         response.forEach((event) => {
        //             this.handleRemoteEvent(event);
        //         });
        //     });
        // }, POLLING_FREQUENCY);
        //
        // this.syncingTimer = setInterval(() => {
        //     this.processEvent();
        // }, SYNCING_FREQUENCY);
    }

    componentWillUnmount() {
        this.pollingTimer = null;
        this.syncingTimer = null;

        this._unsubscribe();
    }

    parseRemoteOrder(remoteObj) {
        let order = {};

        //todo snake case
        order.id = remoteObj.id;
        order.secondaryId = remoteObj.secondary_id;
        order.tableNumber = remoteObj.table_number;
        order.customer = remoteObj.customer;
        order.items = remoteObj.items;
        order.specialNote = remoteObj.special_note;
        order.deliveryNote = remoteObj.delivery_note;
        order.status = remoteObj.status;
        order.total = remoteObj.grand_total;
        order.subTotal = remoteObj.sub_total;
        order.type = remoteObj.type;
        order.tax = remoteObj.tax;
        order.tip = remoteObj.tip;
        order.fees = remoteObj.fees;
        order.discount = remoteObj.discount;
        order.paymentMethod = remoteObj.payment_method;
        order.paymentAmount = remoteObj.payment_amount;
        order.paymentReference = remoteObj.payment_reference;

        return order;
    }

    async loadFullMenu() {
        let category = []
        for (let i = 0; i < 10; i++) {
            category.push({
                category: 'Loading '
            })
        }

        this.stateStore.updateSingleState('full_menu', category);
        getFullMenu().then(response => {
            storeData('full_menu', response);
            this.stateStore.updateState([
                {key: 'full_menu', value: response},
                {key: 'current_category', value: response[0]},
                {key: 'serverStatus', value: true},
            ]);
        }).catch((error) => {
            alert('Cannot load data from server');
            console.error(error);
        });
    }

    printOrder() {
        let receipt = generateOrder(this.state.current_order);
        //BLEPrinter.printText(receipt);
    }

    printReceipt(custId = 0, secId = 0) {
        let serverUrl = getServerUrl();
        let receiptUrl = `${serverUrl}"/order/invoice_thermal/${custId}/${secId}`;
        if(Platform.OS === 'web') {
            window.open(receiptUrl, '_blank');
        } else {
        }
        let receipt = generateReceipt(this.state.current_order);
        //BLEPrinter.printText(receipt);
    }

    logout() {
        this.props.navigation.navigate('Login');
    }

    updateCurrentOrderState(key, value) {
        let current_order = this.state.current_order;
        current_order[key] = value;
        this.stateStore.updateSingleState('current_order', current_order);
    }

    updateCurrentOrderPaymentState(key, value) {
        let current_order = this.state.current_order;
        current_order[key] = value;

        this.calculateCart(current_order);
        this.stateStore.updateSingleState('current_order', current_order);
    }

    updateCurrentCustomerState(key, value) {
        let current_order = this.state.current_order;
        current_order.customer[key] = value;
        this.stateStore.updateSingleState('current_order', current_order);
    }

    selectCategory(category) {
        this.stateStore.updateState([
            {'key': 'current_category', 'value': category},
            {'key': 'search_items', 'value': []},
        ]);
    }

    clearSearchItems() {
        if (this.state.search_items.length !== 0) {
            this.stateStore.updateState([{'key': 'search_items', 'value': []}, {'key': 'search_term', 'value': ''}]);
        }
    }

    generateCategories() {
        return this.state.full_menu.map((category_entry, index) => {
            return <TouchableOpacity key={index} onPress={() => {
                this.selectCategory(category_entry);
            }}>
                <Card containerStyle={{margin: 0}}>
                    <Card.Image source={{uri: category_entry.image}}/>
                    <Button
                        type="clear"
                        buttonStyle={{borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0}}
                        title={category_entry.category}
                        titleStyle={{fontSize: 25, color: 'black'}}
                    />
                </Card>
            </TouchableOpacity>
        });
    };

    searchMenu(term) {

        this.stateStore.updateState([{key: 'search_term', value: term}]);

        if (term.length < 4) {
            return;
        }

        let search_items = [];
        this.state.full_menu.map((category_entry, c_index) => {
            category_entry.items.map((menu_item, m_index) => {
                if (menu_item.name.toLowerCase().includes(term.toLowerCase())) {
                    search_items.push(menu_item);
                }
            });
        });

        if (search_items.length > 0) {
            this.stateStore.updateState([
                {key: 'search_term', value: term},
                {key: 'search_items', value: search_items},
            ]);
        }
    }

    generateFlexMenuItems() {
        let itemWidth = centerColWidth / 3 - 10;
        let menu_items = [];

        if (this.state.search_items.length === 0) {
            menu_items = this.state.current_category.items;
        } else {
            menu_items = this.state.search_items;
        }

        if (menu_items.length > 0) {
            return menu_items.map((menuItem, index) => {
                return <TouchableOpacity onPress={() => {
                    this.addItemToOrder(menuItem);
                }} key={index} style={{backgroundColor: 'white'}}>
                    <Card containerStyle={{margin: 0, width: itemWidth}}>
                        <Card.Image source={require('../assets/images/placeholder.jpg')}/>
                        <Row>
                            <Col size={7}>
                                <Text style={{...styles.menuItem, fontSize: 23, textAlign: 'left'}}>
                                    {menuItem.name}
                                </Text>
                            </Col>
                            <Col size={3}>
                                <Text style={{...styles.menuItem, fontSize: 23, textAlign: 'right'}}>
                                    ${menuItem.price}
                                </Text>
                            </Col>
                        </Row>
                    </Card>
                    {/*<Card style={{width: itemWidth}}>*/}
                    {/*    <CardItem cardBody>*/}
                    {/*        <Image source={require('../assets/images/placeholder.jpg')}*/}
                    {/*               style={{height: 150, width: 150, flex: 1}}/>*/}
                    {/*    </CardItem>*/}
                    {/*    <CardItem>*/}
                    {/*        <Text style={styles.menuItem} numberOfLines={2}>*/}
                    {/*            {sprintf('%\' -30s', menuItem.name)}&nbsp;*/}
                    {/*        </Text>*/}
                    {/*    </CardItem>*/}
                    {/*    <CardItem footer>*/}
                    {/*        <Text style={styles.menuPrice} numberOfLines={2}>*/}
                    {/*            ${menuItem.price}*/}
                    {/*        </Text>*/}
                    {/*    </CardItem>*/}
                    {/*</Card>*/}
                </TouchableOpacity>;
            });
        } else {
            return <Text h4 style={{width: '100%', margin: 10}}>
                No item in {this.state.current_category.category}
            </Text>
        }
    }

    addItemToOrder(item) {
        let current_order = this.state.current_order;

        let foundItem = null;
        for (let i = 0; i < current_order.items.length; i++) {
            if (item.id === current_order.items[i].id) {
                foundItem = current_order.items[i];
                break;
            }
        }

        if (foundItem != null) {
            foundItem.quantity += 1;
        } else {
            item.quantity = 1;
            current_order.items.unshift(item);
        }

        this.calculateCart(current_order);
        this.stateStore.updateState([{'key': 'current_order', 'value': current_order}]);
    }

    updateItemQuantity(item, qty) {
        let current_order = this.state.current_order;

        let foundIndex = null;
        for (let i = 0; i < current_order.items.length; i++) {
            if (item.id === current_order.items[i].id) {
                foundIndex = i;
                break;
            }
        }

        if (foundIndex != null) {
            if (qty === 0) {
                current_order.items.splice(foundIndex, 1);
            } else {
                current_order.items[foundIndex].quantity = qty;
            }
        }

        this.calculateCart(current_order);
        this.stateStore.updateState([{'key': 'current_order', 'value': current_order}]);
    }

    addItemQuantity(item, qty) {
        let current_order = this.state.current_order;

        let foundIndex = null;
        for (let i = 0; i < current_order.items.length; i++) {
            if (item.id === current_order.items[i].id) {
                foundIndex = i;
                break;
            }
        }

        if (foundIndex != null) {
            current_order.items[foundIndex].quantity += qty
            if (current_order.items[foundIndex].quantity <= 0) {
                current_order.items.splice(foundIndex, 1);
            }
        }

        this.calculateCart(current_order);
        this.stateStore.updateState([{'key': 'current_order', 'value': current_order}]);
    }

    calculateCart(current_order) {
        current_order.sub_total = 0;
        current_order.items.forEach((value, index) => {
            current_order.sub_total += (value.price * value.quantity);
        });

        current_order.tax = this.state.location.tax_rate * current_order.sub_total;
        current_order.total = current_order.tax + current_order.sub_total;

        if (current_order.tip && !isNaN(current_order.tip)) {
            current_order.total += parseFloat(current_order.tip);
        }
        if (current_order.fees && !isNaN(current_order.fees)) {
            current_order.total += parseFloat(current_order.fees);
        }
    }

    showOrderTotal() {
        if (!this.state.current_order.hasOwnProperty('sub_total')) {
            return <View/>
        }
        return <View>
            <ListItem noIndent>
                <View
                    style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}
                >
                    <Text style={styles.subTotal}>Sub-Total</Text>
                    <Text
                        style={styles.subTotal}>${this.state.current_order.sub_total.toFixed(2)}</Text>
                </View>
            </ListItem>
            <ListItem noIndent>
                <View
                    style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}
                >
                    <Text style={styles.subTotal}>Tax
                        ({this.state.location.tax_rate})</Text>
                    <Text
                        style={styles.subTotal}>${this.state.current_order.tax.toFixed(2)}</Text>
                </View>
            </ListItem>
            <ListItem noIndent>
                <View
                    style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}
                >
                    <Text style={styles.total}>Total</Text>
                    <Text
                        style={styles.total}>${this.state.current_order.total.toFixed(2)}</Text>
                </View>
            </ListItem>
            <ListItem noIndent>
                <Grid>
                    <Col size={3}>
                        <Button block light onPress={() => {
                            this.showDeletePrompt();
                        }} title={'New'} titleStyle={styles.total} style={{}}/>
                    </Col>
                    <Col size={7}>
                        <Button block success style={{marginLeft: 10}} onPress={() => {
                            if (!this.isEmptyOrder()) {
                                this.setOrderModalVisible(true);
                            }
                        }} title={'Save'} titleStyle={styles.total}/>
                    </Col>
                </Grid>
            </ListItem>
        </View>
    }

    showDeleteP

    generateOrder() {
        return this.state.current_order.items.map((menuItem, index) => {
            return <ListItem key={index} noIndent style={{width: '100%'}} onPress={() => {
                this.showUpdateItemPrompt(menuItem);
            }} bottomDivider>
                <ListItem.Content>
                    <Text style={styles.orderName}>{menuItem.name}</Text>
                    <Text style={styles.orderPrice}>{`x ${menuItem.quantity} $${menuItem.price}`}</Text>
                </ListItem.Content>
            </ListItem>;
        });
    }

    showDeletePrompt() {
        if (this.isEmptyOrder()) {
            return;
        }
        if (Platform.OS === 'web') {
            let action = confirm("Clear the current order?");
            if (action === true) {
                this.stateStore.updateState([{'key': 'current_order', 'value': blankOrder()}]);
            }
        } else {
            Alert.prompt(
                'Clear the current order?',
                'This cannot be undone',
                [
                    {
                        text: 'Cancel',
                        onPress: () => {
                        },
                        style: 'cancel',
                    },
                    {
                        text: 'Clear',
                        onPress: () => {
                            this.stateStore.updateState([{'key': 'current_order', 'value': blankOrder()}]);
                        },
                        style: 'destructive',
                    },
                ],
                'default',
            );
        }
    }

    isEmptyOrder() {
        return this.state.current_order.items.length === 0;
    }

    hasInputError() {
        let order = this.state.current_order;
        if (order.type === DINE_IN && !order.table_number) {
            alert('Missing table number for dine in');
            return true;
        }
        if (order.type === TAKEOUT && !order.customer.name) {
            alert('Missing customer name for takeout');
            return true;
        }
        if (order.type === DELIVERY && !order.customer.address) {
            alert('Missing customer address for delivery');
            return true;
        }
        return false;
    }

    submitOrder() {
        if (this.isEmptyOrder()) {
            return;
        }
        if (this.hasInputError()) {
            return;
        }

        this.loadingModal.showModal(true, "Saving Order");

        let current_order = this.state.current_order;
        let paymentMethod = current_order.payment_method;

        try {
            if (paymentMethod !== CASH) {
                current_order.payment_amount = parseFloat(this.state.current_order.total.toFixed(2));
            } else {
                if (!isNaN(this.state.current_order.payment_amount)) {
                    current_order.payment_amount = parseFloat(this.state.current_order.payment_amount);
                }
            }
        } catch (e) {
            console.log("Bad input for payment amount");
            console.log(e);
        }

        current_order.status = PROCESSING;

        if (current_order.payment_amount >= current_order.total) {
            current_order.status = PAID;
        } else {
            if ((current_order.total - current_order.payment_amount) > 0.01) {
                current_order.status = PAID;
            }
        }

        this.stateStore.updateSingleState('current_order', this.state.current_order);

        let postBody = {
            'order': {
                'id': current_order.id,
                'secondary_id': current_order.secondary_id,
                'fk_location_id': this.state.accountLocationId,
                'type': current_order.type,
                'status': current_order.status,
                'items': current_order.items,
                'customer': current_order.customer,
                'special_note': current_order.special_note,
                'delivery_note': current_order.delivery_note,
                'table_number': current_order.table_number,
                'fees': current_order.fees,
                'tip': current_order.tip,
                'discount': current_order.discount,
                'sub_total': current_order.sub_total,
                'tax': current_order.tax,
                'grand_total': current_order.total,
                'payment_reference': current_order.payment_reference,
                'payment_method': current_order.payment_method,
                'payment_amount': current_order.payment_amount,
            },
        };

        saveOrder(postBody).then(response => {
            if (response.hasOwnProperty('id')) {
                current_order.id = response.id;
                alert("Order placed " + current_order.id);
                this.updateCurrentOrderState("id", current_order.id);
            } else {
                alert("Order error");
                console.log('Order error ' + JSON.stringify(response));
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            this.loadingModal.showModal(false, "");
        });
    }

    setOrderModalVisible(bool) {
        this.stateStore.updateState([{key: 'orderModalVisible', value: bool}]);
    }

    customerMap() {
        return mapFrame(this.state.current_order);
    }

    tableNumberInput() {
        if (this.state.current_order.type === DINE_IN) {
            return <Input label={'Table Number'}
                          value={this.state.current_order.table_number}
                          onChangeText={(term) => {
                              this.updateCurrentOrderState('table_number', term);
                          }} autoCompleteType={'off'} autoCorrect={false}
                          placeholder={'A1'}/>
        }
    }

    customerNameInput() {
        if (this.state.current_order.type === TAKEOUT || this.state.current_order.type === DELIVERY) {
            return <Input label={'Customer Name'}
                          value={this.state.current_order.customer.name}
                          onChangeText={(term) => {
                              this.updateCurrentCustomerState('name', term);
                          }} autoCompleteType={'off'} autoCorrect={false}
                          placeholder={'John Doe'}/>
        }
    }

    phoneNumberInput() {
        if (this.state.current_order.type === TAKEOUT || this.state.current_order.type === DELIVERY) {
            return <Input label={'Phone Number'}
                          value={this.state.current_order.customer.phone}
                          keyboardType="phone-pad"
                          onChangeText={(term) => {
                              this.updateCurrentCustomerState('phone', term);
                          }} autoCompleteType={'off'} autoCorrect={false}
                          placeholder={'000-000-0000'}/>
        }
    }

    customerAddressInput() {
        if (this.state.current_order.type === DELIVERY) {
            return <Input label={'Customer Address'}
                          value={this.state.current_order.customer.address}
                          onChangeText={(term) => {
                              this.updateCurrentCustomerState('address', term);
                          }} autoCompleteType={'off'} autoCorrect={false}
                          placeholder={'Customer full address for delivery'}/>
        }
    }

    customerDeliveryNoteInput() {
        if (this.state.current_order.type === DELIVERY) {
            return <Input label={'Delivery instruction'}
                          value={this.state.current_order.customer.delivery_note}
                          onChangeText={(term) => {
                              this.updateCurrentCustomerState('delivery_note', term);
                          }} autoCompleteType={'off'} autoCorrect={false}
                          placeholder={'Record customer delivery instruction here'}/>
        }
    }

    render() {
        return (
            <Grid style={{height: windowHeight, maxHeight: windowHeight, width: windowWidth, maxWidth: windowWidth}}>
                <HeaderComponent {...this.props} title={'Order'}/>
                <Row size={9}>
                    <Col size={17}>
                        <ScrollView>
                            {this.generateCategories()}
                        </ScrollView>
                    </Col>
                    <Col size={60}>
                        <SearchBar
                            lightTheme
                            inputStyle={{
                                fontSize: 25,
                                color: 'black'
                            }}
                            showLoading={false}
                            clearIcon={{
                                name: 'close',
                                type: ' FontAwesome'
                            }}
                            value={this.state.search_term}
                            onChangeText={(term) => {
                                this.searchMenu(term);
                            }}
                            onClear={() => {
                                this.clearSearchItems();
                            }}
                            autoCompleteType={'off'} autoCorrect={false}
                        />
                        <ScrollView>
                            <View style={{
                                flex: 1,
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                            }}>
                                {this.generateFlexMenuItems()}
                            </View>
                        </ScrollView>
                    </Col>
                    <Col size={25} width={rightColWidth}>
                        <ButtonGroup
                            onPress={(index) => {
                                let type = orderTypes[index]
                                this.updateCurrentOrderState('type', type);
                            }}
                            selectedIndex={orderTypes.indexOf(this.state.current_order.type)}
                            buttons={orderTypes}
                            textStyle={{fontSize: 15}}
                            containerStyle={{height: 50}}
                        />
                        <View style={{
                            flex: 1,
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                        }}>
                            <SwipeListView
                                data={this.state.current_order.items}
                                renderItem={(data, rowMap) => {
                                    let menuItem = data.item;
                                    return <ListItem noIndent style={{width: '100%'}} bottomDivider>
                                        <Row>
                                            <Col size={10}>
                                                <Text style={styles.orderName}>{`x ${menuItem.quantity}`}</Text>
                                            </Col>
                                            <Col size={60}>
                                                <Text style={styles.orderName}>{menuItem.name}</Text>
                                            </Col>
                                            <Col size={30}>
                                                <Text
                                                    style={styles.orderPrice}>{`$${menuItem.price}`}</Text>
                                            </Col>
                                        </Row>
                                    </ListItem>
                                }}
                                renderHiddenItem={(data, rowMap) => (
                                    <View style={styles.rowBack}>
                                        <Icon onPress={() => {
                                            this.addItemQuantity(data.item, -1);
                                        }} name="remove-circle-outline"
                                              size={15}
                                              color="white"
                                              type={'ionicon'}
                                              iconStyle={{
                                                  fontSize: 30,
                                                  color: RED
                                              }}
                                        />
                                        <Icon onPress={() => {
                                            this.addItemQuantity(data.item, 1);
                                        }} name="add-circle-outline"
                                              size={15}
                                              color="white"
                                              type={'ionicon'}
                                              iconStyle={{
                                                  fontSize: 30,
                                                  color: BLUE,
                                                  marginRight: 10
                                              }}
                                        />
                                    </View>
                                )}
                                leftOpenValue={75}
                                rightOpenValue={-75}
                                ref={ref => {
                                    this.scrollView = ref;
                                }}
                                // onContentSizeChange={() => this.scrollView.scrollToEnd({animated: true})}
                            />

                            {/*<ScrollView >*/}
                            {/*    {this.generateOrder()}*/}
                            {/*</ScrollView>*/}
                            {
                                this.showOrderTotal()
                            }
                        </View>
                    </Col>
                </Row>
                <Row size={1}>
                    <FooterComponent {...this.props} activeTabName={'Order'}
                                     badgeCounts={[{'name': 'Sales', 'count': 0}]}
                                     ref={child => {
                                         this.footerComponent = child;
                                     }}/>
                </Row>

                <LoadingModal ref={child => {
                    this.loadingModal = child;
                }} showLoading={true} loadingText={'Loading Menu'}/>

                <Modal
                    style={{
                        position: 'absolute',
                        width: windowWidth,
                        height: windowHeight,
                        backgroundColor: 'white',
                        zIndex: 10,
                        display: this.state.orderModalVisible ? 'block' : 'none',
                        borderColor: '#00000000'
                    }}
                    animationType="slide"
                    transparent={true}
                    visible={this.state.orderModalVisible}
                >
                    <View style={{...styles.centeredView}}>
                        <View style={{...styles.modalView}}>
                            <Header
                                centerComponent={{text: 'Order Detail', style: {color: '#fff', fontSize: 30}}}
                                rightComponent={{
                                    icon: 'close', color: '#fff', onPress: () => {
                                        this.stateStore.updateSingleState('orderModalVisible', false)
                                    }
                                }}
                            />
                            <Grid>
                                <Col size={5}>
                                    <View style={{
                                        width: '80%',
                                        marginLeft: '10%'
                                    }}>
                                        <ButtonGroup
                                            onPress={(index) => {
                                                let type = orderTypes[index]
                                                this.updateCurrentOrderState('type', type);
                                            }}
                                            selectedIndex={orderTypes.indexOf(this.state.current_order.type)}
                                            buttons={orderTypes}
                                            textStyle={{fontSize: 15}}
                                            containerStyle={{height: 50}}
                                        />
                                        {
                                            this.tableNumberInput()
                                        }
                                        <Input label={'Additional Note for order'}
                                               value={this.state.current_order.special_note}
                                               onChangeText={(term) => {
                                                   this.updateCurrentOrderState('special_note', term);
                                               }} autoCompleteType={'off'} autoCorrect={false}
                                               placeholder={'Does customer have any special note for the order?'}/>
                                        {
                                            this.customerNameInput()
                                        }
                                        {
                                            this.phoneNumberInput()
                                        }
                                        {
                                            this.customerAddressInput()
                                        }
                                        {
                                            this.customerDeliveryNoteInput()
                                        }
                                        {this.customerMap()}
                                    </View>
                                </Col>
                                <Col size={5}>
                                    <ButtonGroup
                                        onPress={(index) => {
                                            let type = paymentTypes[index]
                                            this.updateCurrentOrderState('payment_method', type);
                                        }}
                                        selectedIndex={paymentTypes.indexOf(this.state.current_order.payment_method)}
                                        buttons={paymentTypes}
                                        containerStyle={{height: 50, width: '80%', marginLeft: '10%', marginTop: 10}}
                                    />
                                    <Input inputContainerStyle={{
                                        width: '80%',
                                        marginLeft: '10%',
                                        borderWidth: 1,
                                        borderColor: GRAY,
                                        height: 50
                                    }}
                                           label="Collect Amount"
                                           labelStyle={styles.whiteLabel}
                                           inputStyle={{
                                               color: 'black',
                                               textAlign: 'center',
                                               fontSize: 30,
                                               lineHeight: 50
                                           }}
                                           leftIcon={{type: 'ionicon', name: 'logo-usd', iconStyle: styles.inputIcon}}
                                           value={this.state.current_order.payment_amount} autoCapitalize={'none'}
                                           autoCompleteType={'off'}
                                           autoCorrect={false} secureTextEntry={false} onChangeText={(term) => {
                                        this.updateCurrentOrderPaymentState('payment_amount', term);
                                    }}/>

                                    <Input inputContainerStyle={{
                                        width: '80%',
                                        marginLeft: '10%',
                                        borderWidth: 1,
                                        borderColor: GRAY,
                                        height: 50
                                    }}
                                           label="Tip Amount"
                                           labelStyle={styles.whiteLabel}
                                           inputStyle={{
                                               color: 'black',
                                               textAlign: 'center',
                                               fontSize: 30,
                                               lineHeight: 50
                                           }}
                                           leftIcon={{type: 'ionicon', name: 'logo-usd', iconStyle: styles.inputIcon}}
                                           value={this.state.current_order.tip} autoCapitalize={'none'}
                                           autoCompleteType={'off'}
                                           autoCorrect={false} secureTextEntry={false} onChangeText={(term) => {
                                        this.updateCurrentOrderPaymentState('tip', term);
                                    }}/>

                                    <Input inputContainerStyle={{
                                        width: '80%',
                                        marginLeft: '10%',
                                        borderWidth: 1,
                                        borderColor: GRAY,
                                        height: 50
                                    }}
                                           label="Additional Fees"
                                           labelStyle={styles.whiteLabel}
                                           inputStyle={{
                                               color: 'black',
                                               textAlign: 'center',
                                               fontSize: 30,
                                               lineHeight: 50
                                           }}
                                           leftIcon={{type: 'ionicon', name: 'logo-usd', iconStyle: styles.inputIcon}}
                                           value={this.state.current_order.fees} autoCapitalize={'none'}
                                           autoCompleteType={'off'}
                                           autoCorrect={false} secureTextEntry={false} onChangeText={(term) => {
                                        this.updateCurrentOrderPaymentState('fees', term);
                                    }}/>

                                    <View style={{
                                        width: '80%',
                                        marginLeft: '10%',
                                    }}>
                                        <ListItem noIndent>
                                            <View
                                                style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}
                                            >
                                                <Text style={styles.total}>Total</Text>
                                                <Text
                                                    style={styles.total}>${this.state.current_order.total.toFixed(2)}</Text>
                                            </View>
                                        </ListItem>
                                        <ListItem noIndent>
                                            <View
                                                style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}
                                            >
                                                <Text style={styles.total}>Change</Text>
                                                <Text
                                                    style={styles.total}>${(this.state.current_order.payment_amount === 0 ?
                                                    0 : this.state.current_order.payment_amount - this.state.current_order.total).toFixed(2)}</Text>
                                            </View>
                                        </ListItem>
                                    </View>
                                    <Button style={{
                                        width: '80%',
                                        marginLeft: '10%'
                                    }} title={'Save'}
                                            titleStyle={{
                                                fontSize: 30
                                            }}
                                            icon={
                                                <Icon
                                                    name="download-outline"
                                                    size={30}
                                                    color="white"
                                                    type={'ionicon'}
                                                />
                                            } onPress={() => {
                                        this.submitOrder();
                                    }}/>

                                    <Divider style={{
                                        backgroundColor: LIGHT_GRAY,
                                        width: '80%',
                                        marginLeft: '10%',
                                        marginTop: 20,
                                        marginBottom: 20
                                    }}/>

                                    <Row size={1} style={{
                                        width: '80%',
                                        marginLeft: '10%'
                                    }}>
                                        <Col>
                                            <Button block onPress={() => {
                                                this.printOrder({});
                                            }} style={{
                                                marginLeft: 10,
                                                marginRight: 5,
                                            }} title={'Print Order'}/>
                                        </Col>
                                        <Col>
                                            <Button block onPress={() => {
                                                this.printReceipt(this.state.current_order.customer?.id, this.state.current_order.secondary_id);
                                            }} style={{
                                                marginLeft: 5,
                                                marginRight: 5,
                                            }} title={'Print Invoice'}/>
                                        </Col>
                                        <Col>
                                            <Button block onPress={() => {
                                                this.printReceipt({});
                                            }} style={{
                                                marginLeft: 5,
                                                marginRight: 10,
                                            }} title={'Print Receipt'}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Grid>
                        </View>
                    </View>
                </Modal>
            </Grid>
        )
            ;
    }
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        width: windowWidth,
        height: windowHeight,
    },
    headerText: {
        fontSize: 30,
        fontFamily: defaultFont,
    },
    container: {
        paddingTop: 50,
    },
    categoryImage: {
        height: 125,
        width: '100%',
        flex: 1,
    },
    categoryTitle: {
        width: '100%',
        lineHeight: 20,
        fontSize: 20,
        marginTop: 10,
        marginLeft: 0,
        fontFamily: defaultFont,
    },
    menuItem: {
        lineHeight: 20,
        fontSize: 20,
        width: '100%',
        fontWeight: 'bold',
        fontFamily: defaultFont,
    },
    menuPrice: {
        lineHeight: 20,
        fontSize: 20,
        width: '100%',
        textAlign: 'right',
        fontFamily: defaultFont,
    },
    subTotal: {
        fontSize: 20,
        textAlign: 'right',
        fontFamily: defaultFont,
    },
    total: {
        fontSize: 22,
        fontFamily: defaultFont,
        fontWeight: 'bold',
        textAlign: 'right',
        lineHeight: 25,
    },
    orderName: {
        lineHeight: 20,
        fontSize: 18,
        width: '100%',
        fontFamily: defaultFont,
    },
    orderPrice: {
        lineHeight: 20,
        fontSize: 18,
        width: '100%',
        textAlign: 'right',
        fontFamily: defaultFont,
    },
    roundedInput: {
        borderWidth: 1,
        borderColor: GRAY,
    },
    whiteLabel: {
        fontSize: 20,
        color: GRAY,
        marginLeft: '10%',
        marginTop: 10,
        marginBottom: 10
    },
    backTextWhite: {
        color: '#FFF',
    },
    rowFront: {
        alignItems: 'center',
        backgroundColor: '#CCC',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        justifyContent: 'center',
        height: 50,
    },
    rowBack: {
        alignItems: 'center',
        backgroundColor: '#DDD',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
    },
    backRightBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: 75,
    },
    backRightBtnLeft: {
        backgroundColor: 'blue',
        right: 75,
    },
    backRightBtnRight: {
        backgroundColor: 'red',
        right: 0,
    },
});
