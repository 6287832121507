export const CURRENT_PRINTER = "CURRENT_PRINTER";

export const EXTRA_LINE1 = "EXTRA_LINE1";
export const EXTRA_LINE2 = "EXTRA_LINE2";
export const EXTRA_LINE3 = "EXTRA_LINE3";
export const EXTRA_LINE4 = "EXTRA_LINE4";
export const EXTRA_LINE5 = "EXTRA_LINE5";

export const SERVER_URL = "serverUrl";
export const ACCOUNT_USERNAME = "accountUsername";
export const ACCOUNT_PASSWORD = "accountPassword";
export const ACCOUNT_LOCATION_ID = "accountLocationId";
export const AUTH_TYPE = "authType";
export const ACCESS_TOKEN = "accessToken";
export const LOCALE = "locale";

export const POLLING_FREQUENCY = 8000;
export const SYNCING_FREQUENCY = 7000;

export const CASH = 'CASH';
export const CREDIT_CARD = 'CREDIT CARD';
export const GIFT_CARD = 'GIFT CARD';
export const CHECK = 'CHECK';

export const DINE_IN = 'DINE IN';
export const TAKEOUT = 'TAKEOUT';
export const DELIVERY = 'DELIVERY';

export const UNPAID = 'UNPAID';
export const PROCESSING = 'PROCESSING';
export const CANCELLED = 'CANCELLED';

export const DEFAULT_FONT = 'Roboto';

export const RED = '#bf4744';
export const BLUE = '#2463f6';
export const LIGHT_BLUE = '#649fec';
export const GREEN = '#67aa56';
export const LIGHT_GRAY = '#f2f2f2';
export const GRAY = '#a4b0be';
export const TRANSPARENT = '#00000000';
export const TRANSLUCENT = 'rgba(164, 176, 190, 0.5)';

const today = new Date();
export const TODAY_STR = `${today.getMonth()}_${today.getDate()}_${today.getFullYear()}`;
