import React, {Component} from 'react';
import {Alert, Dimensions, Modal, ScrollView, StyleSheet, View} from 'react-native';
import {Avatar, Button, Header, Icon, ListItem, Text} from "react-native-elements";
import {Col, Grid, Row} from 'react-native-easy-grid';
import FooterComponent from './Components/FooterComponent';
import HeaderComponent from './Components/HeaderComponent';
import {
    BLUE,
    DEFAULT_FONT,
    DELIVERY,
    DINE_IN,
    GREEN,
    LIGHT_GRAY,
    PROCESSING,
    RED,
    TRANSLUCENT,
    TRANSPARENT,
} from './lib/Constants';
import {blankOrder} from './lib/Helper';
import StateStore from './lib/StateStore';
import LoadingModal from "./Components/LoadingModal";
import {getWeeklyOrders} from "./lib/Network";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default class SalesScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            weeklyOrders: [],
            showLoading: true,
            orderModalVisible: false,
            currentOrder: blankOrder(),
            location: {
                tax_rate: 0.08
            }
        };

        this.stateStore = new StateStore(this);
    }

    async componentDidMount() {
        await this.loadWeeklyOrders();

        this.loadingModal.showModal(false, "");
    }

    async loadWeeklyOrders() {
        let weeklyOrders = await getWeeklyOrders();

        if (weeklyOrders != null) {
            this.stateStore.updateState([
                {key: 'weeklyOrders', value: weeklyOrders},
            ]);
            this.setFooterBadgeCount(weeklyOrders.length);
        }
    }

    setFooterBadgeCount(count) {
        this.footerComponent.updateBadge('Sales', count);
    }

    extractItemsFromOrder(order) {
        if (!order || !order.items) {
            return '';
        }

        let itemsString = '';

        if (typeof order.items === 'string') {
            try {
                order.items = JSON.parse(order.items);
            } catch (e) {
                order.items = [];
                console.log(e);
            }
        }

        order.items.forEach((item, index) => {
            itemsString += `x${item.quantity} - ${item.name},  `;
        });

        return itemsString.length > 120 ? itemsString.substring(0, 120) + ' and more' : itemsString;
    }

    extractCustomerInfo(order) {
        if (!order || !order.customer) {
            return '';
        }

        try {
            let customer = JSON.parse(order.customer);

            return order.type === DINE_IN ?
                'Table # ' + order['table_number'] : customer['name'] + ', ' + customer['phone'];
        } catch (e) {
            return '';
        }
    }

    extractCustomerTable(order) {
        if (!order || !order.customer) {
            return '';
        }

        try {
            return order.type === DINE_IN ?
                'Table # ' + order['table_number'] : '';
        } catch (e) {
            return '';
        }
    }

    extractCustomerName(order) {
        if (!order || !order.customer) {
            return '';
        }

        try {
            let customer = JSON.parse(order.customer);

            return customer['name'];
        } catch (e) {
            return '';
        }
    }

    extractCustomerPhone(order) {
        if (!order || !order.customer) {
            return '';
        }

        try {
            let customer = JSON.parse(order.customer);

            return customer['phone'];
        } catch (e) {
            return '';
        }
    }

    extractCustomerAddress(order) {
        if (!order || !order.customer) {
            return '';
        }

        try {
            let customer = JSON.parse(order.customer);

            return customer['address'];
        } catch (e) {
            return '';
        }
    }

    showOrderDetail(order) {
        if (typeof order.items == 'string') {
            try {
                console.log(order.items);
                order.items = JSON.parse(order.items);
            } catch (e) {
                order.items = [];
                console.log(e);
            }
        }
        this.stateStore.updateState([
            {key: 'orderModalVisible', value: true},
            {key: 'currentOrder', value: order},
        ]);
    }

    setOrderModalVisible(bool) {
        this.stateStore.updateSingleState('orderModalVisible', bool);
    }

    editOrder(order) {

    }

    weeklyOrdersUI() {
        return this.state.weeklyOrders.map((order, index) => {
            if (order && order.type) {
                order.icon = order.type === DELIVERY ?
                    require('../assets/images/car.png') : order.type === DINE_IN ?
                        require('../assets/images/chair.png') : require('../assets/images/shopping-bag.png');
                return <ListItem thumbnail key={index} style={{
                    borderWidth: 3, borderColor: LIGHT_GRAY, margin: 1
                }} onPress={this.showOrderDetail.bind(this, order)}>
                    <Avatar style={{backgroundColor: order.status === PAID ? GREEN : RED, width: 50, height: 50}}
                            source={order.icon}/>
                    <ListItem.Content>
                        <Row style={{width: '100%'}}>
                            <Col>
                                <Text style={{fontSize: 20, width: '100%'}}>
                                    Order #{order.id} - {this.extractCustomerInfo(order)}
                                </Text>
                            </Col>
                            <Col>
                                <Text style={{
                                    fontSize: 25, lineHeight: 20, textAlign: 'right', width: '100%',
                                    color: order.status === PAID ? GREEN : RED
                                }}>
                                    {order.status} - ${order.grand_total.toFixed(2)}
                                </Text>
                            </Col>
                        </Row>
                        <Row style={{width: '100%', marginTop: 5}}>
                            <Col size={8}>
                                <Text style={{width: '100%', fontSize: 18}}>
                                    {this.extractItemsFromOrder(order)}
                                </Text>
                            </Col>
                            <Col size={2}>
                                <Text style={{fontSize: 18, textAlign: 'right', width: '100%'}}>
                                    {order.created_on}
                                </Text>
                            </Col>
                        </Row>
                    </ListItem.Content>
                </ListItem>;
            }
        });
    }

    editCurrentOrder() {
        this.props.navigation.navigate('Order', {
            current_order: JSON.stringify(this.state.currentOrder),
        });
    }

    filterAllOrders() {
        alert('hi');
    }

    filterDineInOrders() {

    }

    filterDeliveryOrders() {

    }

    filterTakeoutOrders() {

    }

    render() {
        return (
            <Grid style={{height: windowHeight, maxHeight: windowHeight, width: windowWidth, maxWidth: windowWidth}}>
                <HeaderComponent {...this.props} title={'Sales'}/>
                {/*<Header searchBar rounded>*/}
                {/*    <Item>*/}
                {/*        <Icon name="search" type={'FontAwesome5'}/>*/}
                {/*        <Input placeholder="Search"/>*/}
                {/*        <Icon name="shopping-bag" type={'FontAwesome5'}/>*/}
                {/*    </Item>*/}
                {/*    <Button transparent>*/}
                {/*        <Text>Search</Text>*/}
                {/*    </Button>*/}
                {/*</Header>*/}
                <Row size={9}>
                    <Col>
                        {/*<ListItem itemDivider>*/}
                        {/*    <Left>*/}
                        {/*        <Text style={{fontSize: 25}}>{this.state.todayOrders.length} Order(s)</Text>*/}
                        {/*    </Left>*/}
                        {/*    <Right>*/}
                        {/*        <Text>*/}
                        {/*            <Text style={{fontSize: 30}}*/}
                        {/*                  onPress={this.filterAllOrders.bind(this)}>All</Text>*/}
                        {/*            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                        {/*            <Icon name={'chair'} type={'FontAwesome5'}*/}
                        {/*                  onPress={this.filterDineInOrders.bind(this)}/>*/}
                        {/*            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                        {/*            <Icon name={'car'} type={'FontAwesome5'}*/}
                        {/*                  onPress={this.filterDeliveryOrders.bind(this)}/>*/}
                        {/*            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                        {/*            <Icon name={'shopping-bag'} type={'FontAwesome5'}*/}
                        {/*                  onPress={this.filterTakeoutOrders.bind(this)}/>*/}
                        {/*            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                        {/*        </Text>*/}
                        {/*    </Right>*/}
                        {/*</ListItem>*/}
                        {this.weeklyOrdersUI()}
                    </Col>
                </Row>
                <Row size={1}>
                    <FooterComponent {...this.props} activeTabName={'Sales'}
                                     badgeCounts={[{'name': 'Sales', 'count': this.state.weeklyOrders.length}]}
                                     ref={child => {
                                         this.footerComponent = child;
                                     }}/>
                </Row>
                <LoadingModal ref={child => {
                    this.loadingModal = child;
                }} showLoading={true} loadingText={'Loading Orders'}/>

                <Modal
                    style={{
                        position: 'absolute',
                        width: windowWidth,
                        height: windowHeight,
                        backgroundColor: TRANSLUCENT,
                        zIndex: 10,
                        display: this.state.orderModalVisible ? 'block' : 'none',
                        borderColor: '#00000000'
                    }}
                    animationType="slide"
                    transparent={true}
                    visible={this.state.orderModalVisible}
                >
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                            <Header
                                centerComponent={{
                                    text: `Order #${this.state.currentOrder.id}`,
                                    style: {color: '#fff', fontSize: 25}
                                }
                                }
                                rightComponent={{
                                    icon: 'close', type: 'font-awesome', color: '#fff', onPress: () => {
                                        this.setOrderModalVisible(false);
                                    }
                                }}
                            />
                            <Text style={{
                                width: '95%',
                                marginLeft: '2.5%',
                                marginTop: 20
                            }}>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text style={styles.orderDetailLabel}>Order Type</Text>
                                    <Text style={styles.orderDetailLabel}>Order Time</Text>
                                </Col>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text style={styles.orderDetailValue}>{this.state.currentOrder.type}</Text>
                                    <Text style={styles.orderDetailValue}>{this.state.currentOrder.created_on}</Text>
                                </Col>
                            </Text>
                            <Text style={{
                                width: '95%',
                                marginLeft: '2.5%',
                                display: this.state.currentOrder.type === DINE_IN ? 'block' : 'none'
                            }}>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text style={styles.orderDetailLabel}>Table Number</Text>
                                </Col>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text
                                        style={styles.orderDetailValue}>{this.extractCustomerTable(this.state.currentOrder)}</Text>
                                </Col>
                            </Text>
                            <Text style={{
                                width: '95%',
                                marginLeft: '2.5%',
                                marginBottom: 20,
                                display: this.state.currentOrder.type !== DINE_IN ? 'block' : 'none'
                            }}>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text style={styles.orderDetailLabel}>Customer Name</Text>
                                    <Text style={styles.orderDetailLabel}>Phone Number</Text>
                                    <Text style={styles.orderDetailLabel}>Delivery Address</Text>
                                </Col>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text
                                        style={styles.orderDetailValue}>{this.extractCustomerName(this.state.currentOrder)}</Text>
                                    <Text
                                        style={styles.orderDetailValue}>{this.extractCustomerPhone(this.state.currentOrder)}</Text>
                                    <Text
                                        style={styles.orderDetailValue}>{this.extractCustomerAddress(this.state.currentOrder)}</Text>
                                </Col>
                            </Text>
                            <ScrollView style={{
                                borderWidth: 2,
                                borderColor: LIGHT_GRAY
                            }}>
                                <Row style={{margin: 10, borderBottomWidth: 2, borderBottomColor: LIGHT_GRAY}}>
                                    <Col size={6}>
                                        <Text style={styles.orderName}>Name</Text>
                                    </Col>
                                    <Col size={2}>
                                        <Text style={styles.orderPrice}>Qty</Text>
                                    </Col>
                                    <Col size={2}>
                                        <Text style={styles.orderPrice}>Name</Text>
                                    </Col>
                                </Row>
                                {
                                    this.state.currentOrder.items.map((item, index) => {
                                        return <Row key={index} style={{margin: 3}} onPress={() => {
                                            this.showUpdateItemPrompt(item);
                                        }}>
                                            <Col size={6}>
                                                <Text style={styles.orderName}>{item.name}</Text>
                                            </Col>
                                            <Col size={2}>
                                                <Text style={styles.orderPrice}>x {item.quantity}</Text>
                                            </Col>
                                            <Col size={2}>
                                                <Text style={styles.orderPrice}>${item.price.toFixed(2)}</Text>
                                            </Col>
                                        </Row>;
                                    })
                                }
                            </ScrollView>
                            <Text style={{
                                width: '95%',
                                marginLeft: '2.5%',
                                marginTop: 20
                            }}>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text style={styles.orderDetailLabel}>Sub-Total</Text>
                                </Col>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text
                                        style={styles.orderDetailValue}>${this.state.currentOrder.sub_total.toFixed(2)}</Text>
                                </Col>
                            </Text>
                            <Text style={{
                                width: '95%',
                                marginLeft: '2.5%'
                            }}>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text style={styles.orderDetailLabel}>Tax</Text>
                                </Col>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text
                                        style={styles.orderDetailValue}>${this.state.currentOrder.tax.toFixed(2)}</Text>
                                </Col>
                            </Text>
                            <Text style={{
                                width: '95%',
                                marginLeft: '2.5%'
                            }}>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text style={styles.orderDetailLabel}>Fees</Text>
                                </Col>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text
                                        style={styles.orderDetailValue}>${this.state.currentOrder.fees.toFixed(2)}</Text>
                                </Col>
                            </Text>
                            <Text style={{
                                width: '95%',
                                marginLeft: '2.5%'
                            }}>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text style={styles.orderDetailLabel}>Tip</Text>
                                </Col>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text
                                        style={styles.orderDetailValue}>${this.state.currentOrder.tip.toFixed(2)}</Text>
                                </Col>
                            </Text>
                            <Text style={{
                                width: '95%',
                                marginLeft: '2.5%',
                                marginBottom: 20
                            }}>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text style={styles.orderDetailLabel}>Total</Text>
                                </Col>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text
                                        style={styles.orderDetailValue}>${this.state.currentOrder.grand_total.toFixed(2)}</Text>
                                </Col>
                            </Text>
                            <Text style={{
                                width: '95%',
                                marginLeft: '2.5%',
                                marginBottom: 20
                            }}>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Text style={{color: this.state.currentOrder.status === PROCESSING
                                            ? RED : BLUE, fontSize: 20}}>Order
                                        is {this.state.currentOrder.status}</Text>
                                </Col>
                                <Col style={{
                                    width: '50%'
                                }}>
                                    <Button title={' Edit'} icon={
                                        <Icon
                                            name="pencil"
                                            size={20}
                                            color="white"
                                            type={'font-awesome'}
                                        />
                                    } onPress={this.editCurrentOrder.bind(this)}/>
                                </Col>
                            </Text>
                        </View>
                    </View>
                </Modal>
            </Grid>
        );
    }
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        width: 600,
        height: windowHeight - 50,
        margin: 25
    },
    headerText: {
        fontSize: 30,
    },
    container: {
        paddingTop: 50,
    },
    categoryImage: {
        height: 125,
        width: '100%',
        flex: 1,
    },
    categoryTitle: {
        width: '100%',
        lineHeight: 20,
        fontSize: 20,
        marginTop: 10,
        marginRight: 5,
        marginLeft: 10,
    },
    menuItem: {
        lineHeight: 20,
        fontSize: 20,
        width: '100%',
        fontWeight: 'bold',
    },
    menuPrice: {
        lineHeight: 20,
        fontSize: 20,
        width: '100%',
        textAlign: 'right',
    },
    subTotal: {
        fontSize: 20,
        textAlign: 'right',
    },
    total: {
        fontSize: 22,
        fontWeight: 'bold',
        textAlign: 'right',
        lineHeight: 25,
    },
    orderName: {
        lineHeight: 25,
        fontSize: 25,
        width: '100%',
    },
    orderPrice: {
        lineHeight: 25,
        fontSize: 25,
        width: '100%',
        textAlign: 'right',
    },
    orderDetailLabel: {
        lineHeight: 25,
        fontSize: 20,
        width: '100%',
    },
    orderDetailValue: {
        lineHeight: 25,
        fontSize: 20,
        width: '100%',
        textAlign: 'right',
    }
});
