import React from 'react';

import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';

import LoginScreen from "./src/LoginScreen";
import OrderScreen from "./src/OrderScreen";
import SalesScreen from "./src/SalesScreen";
import POSScreen from "./src/POSScreen";
import CatalogScreen from "./src/CatalogScreen";

const Stack = createStackNavigator();

function App() {
    return (
        <NavigationContainer initialRouteName="CatalogReadOnlyScreen" screenOptions={{
            headerShown: false,
            unmountInactiveRoutes: true,
            animationEnabled: false,
        }}>
            <Stack.Navigator>
                {/*<Stack.Screen name="CatalogReadOnlyScreen" component={CatalogReadOnlyScreen} options={{headerShown: false}}/>*/}
                <Stack.Screen name="Catalog" component={CatalogScreen} options={{headerShown: false}}/>
                <Stack.Screen name="Login" component={LoginScreen} options={{headerShown: false}}/>
                <Stack.Screen name="POS" component={POSScreen} options={{headerShown: false}}/>
                <Stack.Screen name="Order" component={OrderScreen} options={{headerShown: false}}/>
                <Stack.Screen name="Sales" component={SalesScreen} options={{headerShown: false}}/>
            </Stack.Navigator>
        </NavigationContainer>
    );
}

export default App;
