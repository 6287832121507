
export default class StateStore {
    constructor(ref) {
        this.ref = ref;
    }

    updateSingleState(key, value, callback = function(prevState, props) {}) {
        this.updateState([{key: key, value: value}], callback);
    }

    updateState(mapArray, callback = function (prevState, props) {}) {
        let thatState = {...this.ref.state};

        mapArray.forEach((map, index) => {
            thatState[map.key] = map.value;
        });

        this.ref.setState({...thatState}, (prevState, props) => {
            callback(prevState, props);
        });
    }
}
