import {
    getAccessToken,
    getAccountLocationId,
    getAccountPassword,
    getAccountUsername,
    getServerUrl, getSessionId,
} from './Storage';

export const authenticate = async (userName, password, locationId) => {
    try {
        let serverUrl = await getServerUrl();
        let authUrl = serverUrl+"/auth/authenticate";

        let response = await fetch(authUrl, {
            method: 'POST',
            body: JSON.stringify({"username": userName, "password": password, "location_id": locationId})
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
};

export const invalidate_auth = async (accessToken) => {
    try {
        let serverUrl = await getServerUrl();
        let authUrl = serverUrl+"/auth/invalidate_auth/"+accessToken;

        let response = await fetch(authUrl, {
            method: 'POST',
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
};

export const findCustomer = async (input) => {
    try {
        let serverUrl = await getServerUrl();
        let destUrl = serverUrl+"/user/find_customer/?identifier="+input;

        let response = await fetch(destUrl, {
            method: 'GET',
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
};

export const loadRemoteCustomerOrder = async (orderId) => {
    //secondary id
    try {
        let response = await fetch(await getServerUrl() + '/order/order_edit/'+orderId, {
            method: 'GET',
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const loadRemoteOrderById = async (orderId) => {
    try {
        let response = await fetch(await getServerUrl() + '/order/order_get/'+orderId, {
            method: 'GET',
            headers: {
                'X-Username': await getAccountUsername(),
                'X-Password': await getAccountPassword(),
                'X-Location-Id': await getAccountLocationId(),
                'X-Auth-Type': 'business',
            },
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const metaData = async () => {
    try {
        let response = await fetch(await getServerUrl() + '/meta/bootstrap', {
            method: 'GET',
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
};

export const getFullMenu = async () => {
    try {
        let response = await fetch(await getServerUrl() + '/menu/full/'+await getAccessToken(), {
            method: 'GET',
        });

        return await response.json();
    } catch (error) {
        console.error(error);
    }
};

export const getFullMenuView = async (locationId) => {
    try {
        let response = await fetch(await getServerUrl() + '/menu/full_view/'+locationId, {
            method: 'GET',
        });
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};

export const saveOrder = async (postBody, accessToken) => {
    console.log(postBody);
    try {
        let response = await fetch(await getServerUrl() + `/order/order_save/${accessToken}`, {
            method: 'POST',
            body: JSON.stringify(postBody),
        });
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};

export const getWeeklyOrders = async () => {
    try {
        let response = await fetch(await getServerUrl() + '/order/get_orders', {
            method: 'GET',
            headers: {
                'X-Username': await getAccountUsername(),
                'X-Password': await getAccountPassword(),
                'X-Location-Id': await getAccountLocationId()
            },
        });
        return await response.json();
    } catch (error) {
        console.error(error);
    }
};

export const sendEmailForOrder = async (orderId) => {
    try {
        let response = await fetch(await getServerUrl() + '/email/customer_invoice/'+orderId, {
            method: 'POST',
        });

        return await response.json();
    } catch (error) {
        console.error(error);
    }
};
