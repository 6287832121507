import React, {Component} from 'react';
import {StyleSheet, View, ActivityIndicator} from 'react-native';
import {Header, ListItem, BottomSheet} from 'react-native-elements';
import {getSessionId, removeValue} from '../lib/Storage';
import {GRAY, TODAY_STR} from '../lib/Constants';
import StateStore from "../lib/StateStore";

export default class HeaderComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sessionId: "",
        };

        this.stateStore = new StateStore(this);
    }

    async componentDidMount() {
    }

    async sync() {

    }

    toggleActionSheet(visible) {
        this.stateStore.updateSingleState('actionSheetVisible', visible);
    }

    logout() {
        this.props.navigation.navigate("Login");
    }

    render() {
        return (
            <View>
                <Header
                    // leftComponent={{
                    //     icon: 'logout', type: 'Feather', color: '#fff', onPress: () => {
                    //         this.logout();
                    //     }
                    // }}
                    centerComponent={{text: this.props.title, style: {color: '#fff', fontSize: 25, fontWeight: 'bold'}}}
                    // rightComponent={this.state.syncing ? <ActivityIndicator size="large" color={GRAY} /> : {
                    //     icon: 'refresh', type: 'font-awesome', color: '#fff', onPress: this.sync
                    // }}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    headerText: {
        fontSize: 30,
    },
});
