import React, {Component} from 'react';
import {Platform, ScrollView, StyleSheet, View} from 'react-native';
import {Button, Card, Header, Icon, SearchBar, Text} from 'react-native-elements';
import HTML from "react-native-render-html";
import {Col, Row} from 'react-native-easy-grid';
import {GRAY, RED,} from './lib/Constants';
import {getFullMenuView, metaData} from './lib/Network';
import {windowHeight, windowWidth} from './lib/Helper';
import StateStore from './lib/StateStore';
import LoadingModal from "./Components/LoadingModal";

//1536 x 768
const defaultFont = 'Roboto';

//const leftColWidth = windowWidth * .15;
//const centerColWidth = windowWidth * .85;
//const rightColWidth = windowWidth - leftColWidth - centerColWidth;

export default class CatalogScreen extends Component {

    constructor(props) {

        super(props);

        let predefinedLocation = null;

        if (Platform.OS === 'web') {
            const urlParams = new URLSearchParams(window.location.search);
            predefinedLocation = urlParams.get('locationId') ? urlParams.get('locationId') : predefinedLocation;
        }

        this.state = {
            loginModalVisible: false,
            full_menu: [],
            current_category: {items: []},
            search_term: '',
            search_items: [],
            meta_data: {
                "order_types": [],
                "order_status": [],
                "payment_methods": [],
                "catalog_status": [],
                "locations": []
            },
            selectedLocation: null,
            predefinedLocation: predefinedLocation
        };

        this.stateStore = new StateStore(this);
    }

    async componentDidMount() {
        await this.loadMetaData(() => {
            let foundLocation = this.state.meta_data.locations[0];
            if (this.state.predefinedLocation) {
                let foundLocation = this.getLocation(this.state.predefinedLocation);
            }

            this.stateStore.updateSingleState('selectedLocation', foundLocation, () => {
                this.loadFullMenu(() => {
                    this.loadingModal.showModal(false, "");
                });
            });
        });
    }

    async loadFullMenu(callback = null) {
        let category = []
        for (let i = 0; i < 10; i++) {
            category.push({
                category: 'Loading '
            })
        }

        this.stateStore.updateSingleState('full_menu', category);
        getFullMenuView(this.state.selectedLocation.id).then(response => {
            this.stateStore.updateState([
                {key: 'full_menu', value: response},
                {key: 'current_category', value: response[0]}
            ], (prevState, props) => {
                if (callback) {
                    callback(prevState, props);
                }
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    async loadMetaData(callback = null) {
        metaData().then(response => {
            this.stateStore.updateState([
                {key: 'meta_data', value: response}
            ], (prevState, props) => {
                if (callback) {
                    callback(prevState, props);
                }
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    getLocation(locationId) {
        if (this.state.meta_data?.locations) {
            let allLocations = this.state.meta_data.locations;
            allLocations.forEach(location => {
                return location
            });
        }
        return location
    }

    selectCategory(category) {
        this.stateStore.updateState([
            {'key': 'current_category', 'value': category},
            {'key': 'search_items', 'value': []},
        ]);
    }

    clearSearchItems() {
        if (this.state.search_items.length !== 0) {
            this.stateStore.updateState([{'key': 'search_items', 'value': []}, {'key': 'search_term', 'value': ''}]);
        }
    }

    generateCategories() {
        return this.state.full_menu.map((category_entry, index) => {
            return <View key={index}>
                <Card containerStyle={{margin: 10, height: '10vh'}}>
                    {/*<Card.Image source={{uri: category_entry.image}}/>*/}
                    <Button
                        type="clear"
                        buttonStyle={{borderRadius: 0, marginLeft: 0, marginRight: 0,
                            marginBottom: 0, width: '100%', height: '100%'}}
                        title={category_entry.category}
                        titleStyle={{fontSize: '1.25vw', color: 'black', lineHeight: '10vh'}}
                        onPress={() => {
                            this.selectCategory(category_entry);
                        }}
                    />
                </Card>
            </View>
        });
    };

    searchMenu(term) {

        this.stateStore.updateState([{key: 'search_term', value: term}]);

        let search_items = [];
        this.state.full_menu.map((category_entry, c_index) => {
            category_entry.items.map((menu_item, m_index) => {
                if (menu_item.name.toLowerCase().includes(term.toLowerCase())
                    || menu_item.item_number == term) {
                    search_items.push(menu_item);
                }
            });
        });

        if (search_items.length > 0) {
            this.stateStore.updateState([
                {key: 'search_term', value: term},
                {key: 'search_items', value: search_items},
            ]);
        }
    }

    generateFlexMenuItems() {
        let itemWidth = '17vw';
        let itemHeight = '15vw';
        let menu_items = [];

        if (this.state.search_items.length === 0) {
            menu_items = this.state.current_category.items;
        } else {
            menu_items = this.state.search_items;
        }

        if (menu_items.length > 0 && this.state.meta_data.catalog_status.length > 0) {
            return menu_items.map((menuItem, index) => {
                if (menuItem.hidden && !this.state.showHidden) {
                    return;
                }
                return <Card containerStyle={{
                    margin: 0,
                    width: itemWidth,
                    borderBottomWidth: 0,
                    borderBottomColor: 'white'
                }} key={index} style={{backgroundColor: 'white'}}>
                    <Card.Image source={{uri: menuItem.image}} style={{
                        height: itemHeight
                    }}>
                        <Text style={{display: 'none'}}>
                            {menuItem.case_upc}
                        </Text>
                        <Row>
                            <Col>
                                <Text style={{
                                    textAlign: 'left',
                                    color: RED,
                                    fontWeight: 'bold',
                                    fontSize: '1.25vw'
                                }}>
                                    {
                                        menuItem.fk_catalog_status_id ? this.state.meta_data.catalog_status.find(cs => cs.id === menuItem.fk_catalog_status_id).name : ''
                                    }
                                </Text>
                            </Col>
                            <Col>
                                <Text style={{
                                    textAlign: 'right',
                                    fontSize: '1.25vw'
                                }}>
                                    {menuItem.case_count == null || menuItem.case_count === 1 ? '' : menuItem.case_count + 'pcs/pk'}
                                </Text>
                            </Col>
                        </Row>
                        <Row style={{
                            marginTop: 'auto',
                        }}>
                            <Col>
                                <Text style={{
                                    textAlign: 'center',
                                    fontSize: '1.25vw',
                                    marginTop: '0',
                                    width: '200%',
                                    transform: [{rotate: '-45deg'}],
                                    color: RED
                                }}>
                                    {this.state.selectedLocation?.name}
                                </Text>
                                <Text style={{
                                    textAlign: 'left',
                                    fontSize: '1vw',
                                    marginTop: 'auto',
                                }}>
                                    {menuItem.item_number}
                                </Text>
                            </Col>
                            <Col>
                                <Text style={{
                                    textAlign: 'right',
                                    fontSize: '1vw',
                                    marginTop: 'auto',
                                }}>
                                    {menuItem.size ? menuItem.size : ''}
                                </Text>
                            </Col>
                        </Row>
                    </Card.Image>
                    <Row style={{marginTop: '1vh'}}>
                        <Col size={7}>
                            <Text style={{...styles.menuItem, fontSize: '1vw', textAlign: 'left'}}>
                                {menuItem.name}
                            </Text>
                        </Col>
                        <Col size={3}>
                            <Text
                                style={{...styles.menuItem, fontSize: '1vw', textAlign: 'right', fontWeight: 'bold'}}>
                                {menuItem.price ? menuItem.price.toFixed(2).padStart(1, '$') : 'Login to Order'}
                            </Text>
                        </Col>
                    </Row>
                </Card>
            });
        } else {
            return <Text h4 style={{width: '100%', margin: 10}}>
                {/*{this.state.current_category.category}*/}
                Loading...
            </Text>
        }
    }

    render() {
        return (
            <View style={{height: '100vh', maxHeight: '100vh', width: '100vw', maxWidth: '100vw'}}>
                <Header containerStyle={{height: '5vh'}}
                        centerComponent={{
                            text: this.state.selectedLocation?.name,
                            style: {color: '#fff', fontSize: 28, lineHeight: 50}
                        }}
                        rightComponent={{
                            text: 'Login',
                            style: {color: '#fff', fontSize: 20, lineHeight: 50},
                            onPress: () => {
                                this.props.navigation.navigate('Login');
                            }}
                        }
                />
                <View style={{width: '15vw', height: '90vh', position: 'absolute', left: 0, top: 50}}>
                    <ScrollView>
                        <Text style={{textAlign: 'center', marginTop: 10, fontSize: 18}}>
                            Categories
                        </Text>
                        {this.generateCategories()}
                    </ScrollView>
                </View>
                <View style={{
                    width: '85vw',
                    height: '90vh',
                    position: 'absolute',
                    left: '15vw',
                    top: 50
                }}>
                    <SearchBar
                        lightTheme
                        placeholder="Type here to search..."
                        inputStyle={{
                            fontSize: 25,
                            color: 'black'
                        }}
                        showLoading={false}
                        clearIcon={{
                            name: 'close',
                            type: ' FontAwesome'
                        }}
                        value={this.state.search_term}
                        onChangeText={(term) => {
                            this.searchMenu(term);
                        }}
                        onClear={() => {
                            this.clearSearchItems();
                        }}
                        autoCompleteType={'off'} autoCorrect={false}
                    />
                    <ScrollView>
                        <View style={{
                            flex: 1,
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                        }}>
                            {this.generateFlexMenuItems()}
                        </View>
                    </ScrollView>
                </View>

                <LoadingModal ref={child => {
                    this.loadingModal = child;
                }} showLoading={true} loadingText={'Loading Menu'}/>

            </View>
        );
    }
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        width: windowWidth,
        height: windowHeight,
    },
    headerText: {
        fontSize: 30,
        fontFamily: defaultFont,
    },
    container: {
        paddingTop: 50,
    },
    categoryImage: {
        height: 125,
        width: '100%',
        flex: 1,
    },
    categoryTitle: {
        width: '100%',
        lineHeight: 20,
        fontSize: 20,
        marginTop: 10,
        marginLeft: 0,
        fontFamily: defaultFont,
    },
    menuItem: {
        lineHeight: 20,
        fontSize: 20,
        width: '100%',
        fontWeight: 'bold',
        fontFamily: defaultFont,
    },
    menuPrice: {
        lineHeight: 20,
        fontSize: 20,
        width: '100%',
        textAlign: 'right',
        fontFamily: defaultFont,
    },
    subTotal: {
        fontSize: 20,
        textAlign: 'right',
        fontFamily: defaultFont,
    },
    total: {
        fontSize: 22,
        fontFamily: defaultFont,
        fontWeight: 'bold',
        textAlign: 'right',
        lineHeight: 25,
    },
    orderName: {
        lineHeight: 20,
        fontSize: 18,
        width: '100%',
        fontFamily: defaultFont,
    },
    orderPrice: {
        lineHeight: 20,
        fontSize: 18,
        width: '100%',
        textAlign: 'right',
        fontFamily: defaultFont,
    },
    roundedInput: {
        borderWidth: 1,
        borderColor: GRAY,
    },
    whiteLabel: {
        fontSize: 20,
        color: GRAY,
        marginLeft: '10%',
        marginTop: 10,
        marginBottom: 10
    },
    backTextWhite: {
        color: '#FFF',
    },
    rowFront: {
        alignItems: 'center',
        backgroundColor: '#CCC',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        justifyContent: 'center',
        height: 50,
    },
    rowBack: {
        alignItems: 'center',
        backgroundColor: '#DDD',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
    },
    backRightBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: 75,
    },
    backRightBtnLeft: {
        backgroundColor: 'blue',
        right: 75,
    },
    backRightBtnRight: {
        backgroundColor: 'red',
        right: 0,
    },
});
