import {CASH, DINE_IN, UNPAID} from './Constants';
import {Dimensions, Platform, View} from "react-native";
import {WebView} from "react-native-webview";
import React from "react";

//1024x768

//1366x768

//export const windowWidth = Dimensions.get('window').width >= 1366 ? Dimensions.get('window').width : 1366;
//export const windowHeight = (Dimensions.get('window').height >= 768 ? Dimensions.get('window').height : 768);

export const windowWidth = 1536;
export const windowHeight = 768;

export const uuidv4 = function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const random8Digits = function uuidv4() {
    return Math.floor(10000000 + Math.random() * 90000000);
}

export const blankOrder = function () {
    //update tax rate
    return {
        secondary_id: random8Digits(),
        table_number: '',
        customer: {
            id: null,
            name: '',
            phone: '',
            email: '',
            address_1: '',
            address_2: '',
            company_name: '',
        },
        special_note: '',
        delivery_note: '',
        items: [], total: 0, grand_total: 0, sub_total: 0, type: "PICK UP", tax: 0, tip: 0, discount: 0, fees: 0,
        status: UNPAID, payment_method: CASH, payment_amount: 0,
        payment_reference: ''
    };
}
