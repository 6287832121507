import React, {Component} from 'react';
import {Alert, Modal, Platform, ScrollView, StyleSheet, TextInput, TouchableOpacity, View} from 'react-native';
import {Button, ButtonGroup, Card, Header, Icon, Input, ListItem, SearchBar, Text} from 'react-native-elements';
import HTML from "react-native-render-html";
import {Col, Grid, Row} from 'react-native-easy-grid';
import {
    getAccessToken,
    getAccountLocationId,
    getAccountUsername,
    getAuthType,
    getLocale,
    getServerUrl,
} from './lib/Storage';
import {BLUE, CASH, GRAY, LIGHT_GRAY, PROCESSING, RED, TRANSLUCENT,} from './lib/Constants';
import {
    findCustomer,
    getFullMenu,
    invalidate_auth,
    loadRemoteCustomerOrder,
    loadRemoteOrderById,
    metaData,
    saveOrder,
    sendEmailForOrder
} from './lib/Network';
import {blankOrder, windowHeight, windowWidth} from './lib/Helper';
import StateStore from './lib/StateStore';
import LoadingModal from "./Components/LoadingModal";
import {translator} from "./lib/Translator";

//1366 × 768
const defaultFont = 'Arial';

const leftColWidth = windowWidth * .15;
const centerColWidth = windowWidth * .60;
const rightColWidth = windowWidth - leftColWidth - centerColWidth;

const SOLD_OUT = 6;

export default class POSScreen extends Component {

    constructor(props) {
        super(props);

        //default params
        let orderId = null;
        let username = null;
        let customerId = null;
        let query = '';
        let showHidden = false;

        if (Platform.OS === 'web') {
            const urlParams = new URLSearchParams(window.location.search);
            orderId = urlParams.get('orderId') ? urlParams.get('orderId') : orderId;
            customerId = urlParams.get('customerId') ? urlParams.get('customerId') : customerId;
            query = urlParams.get('query');
            showHidden = urlParams.get('showHidden');
        }

        this.state = {
            paymentModalVisible: false,
            itemDetailModalVisible: false,
            customerInfoModalVisible: false,
            full_menu: [],
            current_category: {items: []},
            current_order: blankOrder(),
            search_term: query,
            searchCustomerInput: '',
            search_items: [],
            serverStatus: false,
            meta_data: {
                "order_types": [],
                "order_status": [],
                "payment_methods": [],
                "catalog_status": [],
                "locations": []
            },
            authType: 'CUSTOMER',
            accessToken: null,
            customerId: customerId,
            orderId: orderId,
            showHidden: showHidden,
            selectedLocation: null,
            printDisabled: true,
            scanMode: false,
            i18n: translator('en'),
            customer: null
        };

        this.stateStore = new StateStore(this);
    }

    async componentDidMount() {

        await this.loadUsername();
        await this.loadAccessToken();
        await this.loadAuthType();

        //attach username to customer if isCustomer

        let locale = 'en';
        await getLocale();
        this.stateStore.updateState([
            {key: 'i18n', value: translator(locale)},
        ]);

        //todo send location id
        await this.loadFullMenu(() => {
            if (this.state.search_term && this.state.search_term.length > 0) {
                this.searchMenu(this.state.search_term);
            }

            if (this.state.orderId) {
                this.loadCustomerOrder(this.state.orderId);
            }
        });
        await this.loadMetaData(() => {
            this.loadingModal.showModal(false, "");

            let locationId = getAccountLocationId().then(locationId => {
                if (locationId) {
                    this.selectLocation(locationId);
                }
            })
        });

        if (this.isCustomer()) {
            this.searchCustomer(this.state.username);
        }

        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            let routeParams = this.props.route.params;
            if (routeParams && routeParams.hasOwnProperty('locationId')) {
                try {
                    let locationId = routeParams['locationId']
                    this.selectLocation(locationId);
                } catch (e) {
                }
            }
        });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    reloadCatalog() {
        this.loadingModal.showModal(true, "Reloading");
        this.loadFullMenu(() => {
            this.loadingModal.showModal(false, "");
        });
    }

    async loadUsername() {
        let username = await getAccountUsername();
        this.stateStore.updateSingleState('username', username);
    }

    async loadAccessToken() {
        let accessToken = await getAccessToken();
        this.stateStore.updateSingleState('accessToken', accessToken);
    }

    async loadAuthType() {
        let authType = await getAuthType();
        if (!authType) {
            authType = 'CUSTOMER';
        }
        this.stateStore.updateSingleState('authType', authType.toUpperCase());
    }

    async loadFullMenu(callback = null) {
        let category = []
        for (let i = 0; i < 10; i++) {
            category.push({
                category: 'Loading '
            })
        }

        this.stateStore.updateSingleState('full_menu', category);
        getFullMenu().then(response => {
            this.stateStore.updateState([
                {key: 'full_menu', value: response},
                {key: 'current_category', value: response[0]},
                {key: 'serverStatus', value: true},
            ], (prevState, props) => {
                if (callback) {
                    callback(prevState, props);
                }
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    async loadMetaData(callback = null) {
        metaData().then(response => {
            this.stateStore.updateState([
                {key: 'meta_data', value: response},
                {key: 'serverStatus', value: true},
            ], (prevState, props) => {
                if (callback) {
                    callback(prevState, props);
                }
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    async loadCustomerOrder(orderId, callback = null) {
        //sid
        loadRemoteCustomerOrder(orderId).then(response => {
            if (response) {
                let remoteOrder = this.parseRemoteOrder(response);
                this.stateStore.updateSingleState('current_order', remoteOrder);
                if (callback) {
                    callback(remoteOrder);
                }
            } else {
                alert(this.state.i18n.t('cantFindOrder') + ' #' + orderId);
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    async loadOrderById(orderId, callback = null) {
        loadRemoteOrderById(orderId).then(response => {
            if (response) {
                let remoteOrder = this.parseRemoteOrder(response);
                this.stateStore.updateSingleState('current_order', remoteOrder);
            } else {
                alert(`${this.state.i18n.t('cantFindOrder')} #${orderId}`);
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            if (callback) {
                callback();
            }
        });
    }

    async loadCustomer(input, callback = null) {
        findCustomer(input).then(response => {
            if (response) {
                let current_order = this.state.current_order;
                current_order.customer = response;
                current_order.fk_customer_id = response.id;
                this.stateStore.updateSingleState('current_order', current_order);
            } else {
                alert(`${this.state.i18n.t('cantFindCustomer')} by ${input}`);
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            if (callback) {
                callback();
            }
        });
    }

    parseRemoteOrder(remoteObj) {
        let order = blankOrder();

        order.id = remoteObj.id;
        order.secondary_id = remoteObj.secondary_id;
        order.table_number = remoteObj.table_number;
        order.special_note = remoteObj.special_note;
        order.delivery_note = remoteObj.delivery_note;
        order.status = remoteObj.status;
        order.total = remoteObj.grand_total;
        order.sub_total = remoteObj.sub_total;
        order.type = remoteObj.type;
        order.tax = remoteObj.tax;
        order.tip = remoteObj.tip;
        order.fees = remoteObj.fees;
        order.discount = remoteObj.discount;
        order.payment_method = remoteObj.payment_method;
        order.payment_amount = remoteObj.payment_amount;
        order.payment_reference = remoteObj.payment_reference;
        order.fk_customer_id = remoteObj.fk_customer_id;

        //need to attach customer id correctly
        if (remoteObj.customer) {
            order.customer = JSON.parse(remoteObj.customer);
        }
        if (remoteObj.items) {
            order.items = JSON.parse(remoteObj.items);
        }

        return order;
    }

    selectLocation(locationId) {
        if (this.state.meta_data?.locations) {
            let allLocations = this.state.meta_data.locations;
            allLocations.forEach(location => {
                if (location.id === locationId) {
                    this.stateStore.updateSingleState('selectedLocation', location);
                }
            });
        }
    }

    selectCategory(category) {
        this.stateStore.updateState([
            {'key': 'current_category', 'value': category},
            {'key': 'search_items', 'value': []},
        ]);
    }

    clearSearchItems() {
        if (this.state.search_items.length !== 0) {
            this.stateStore.updateState([{'key': 'search_items', 'value': []}]);
        }
    }


    generateCategories() {
        return this.state.full_menu.map((category_entry, index) => {
            return <View key={index}>
                <Card containerStyle={{margin: 10}}>
                    {/*<Card.Image source={{uri: category_entry.image}}/>*/}
                    <Button
                        type="clear"
                        buttonStyle={{borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0}}
                        title={this.state.i18n.locale === 'en' ? category_entry.category : category_entry?.category_i18n}
                        titleStyle={{fontSize: 20, color: 'black'}}
                        onPress={() => {
                            this.selectCategory(category_entry);
                        }}
                    />
                </Card>
            </View>
        });
    };

    searchMenu(term) {

        //this.stateStore.updateState([{key: 'search_term', value: term}]);

        let search_items = [];
        this.state.full_menu.map((category_entry, c_index) => {
            category_entry.items.map((menu_item, m_index) => {
                if (menu_item.name.toLowerCase().includes(term.toLowerCase())
                    || menu_item.item_number == term) {
                    search_items.push(menu_item);
                }
            });
        });

        if (search_items.length > 0) {
            this.stateStore.updateState([
                {key: 'search_items', value: search_items},
            ]);
        }
    }

    generateFlexMenuItems() {
        let itemWidth = (centerColWidth - 20) / 4;
        let itemHeight = itemWidth - 50;
        let menu_items = [];

        if (this.state.search_items.length === 0) {
            menu_items = this.state.current_category.items;
        } else {
            menu_items = this.state.search_items;
        }

        if (menu_items.length > 0 && this.state.meta_data.catalog_status.length > 0) {
            return menu_items.map((menuItem, index) => {
                if (menuItem.hidden && !this.state.showHidden) {
                    return;
                }
                return <Card containerStyle={{
                    margin: 0,
                    width: itemWidth,
                    borderBottomWidth: 0,
                    borderBottomColor: 'white'
                }} key={index} style={{backgroundColor: 'white'}}>
                    <Card.Image source={{uri: menuItem.image}} style={{
                        height: itemHeight
                    }} onPress={() => {
                        if (this.state.selectedLocation?.kiosk) {
                            this.selectItem(menuItem);
                        }
                    }}>
                        <Text style={{display: 'none'}}>
                            {menuItem.case_upc}
                        </Text>
                        <Row>
                            <Col>
                                <Text style={{
                                    textAlign: 'left',
                                    color: RED,
                                    fontWeight: 'bold',
                                    fontSize: 15
                                }}>
                                    {
                                        menuItem.fk_catalog_status_id ? this.state.meta_data.catalog_status.find(cs => cs.id === menuItem.fk_catalog_status_id).name : ''
                                    }
                                </Text>
                            </Col>
                            <Col>
                                <Text style={{
                                    textAlign: 'right',
                                    fontSize: 15
                                }}>
                                    {menuItem.case_count == null || menuItem.case_count === 1 ? '' : menuItem.case_count + 'pcs/pk'}
                                </Text>
                            </Col>
                        </Row>
                        <Row style={{
                            marginTop: 'auto',
                        }}>
                            <Col>
                                <Text style={{
                                    textAlign: 'center',
                                    fontSize: 15,
                                    marginTop: '0',
                                    width: '200%',
                                    transform: [{rotate: '-45deg'}],
                                    color: RED
                                }}>
                                    {this.state.selectedLocation?.name}
                                </Text>
                                <Text style={{
                                    textAlign: 'left',
                                    fontSize: 15,
                                    marginTop: 'auto',
                                }}>
                                    {menuItem.item_number}
                                </Text>
                            </Col>
                            <Col>
                                <Text style={{
                                    textAlign: 'right',
                                    fontSize: 13,
                                    marginTop: 'auto',
                                }}>
                                    {menuItem.size ? menuItem.size : ''}
                                </Text>
                            </Col>
                        </Row>
                    </Card.Image>
                    <Row style={{marginTop: 10}}>
                        <Col size={7}>
                            <Text style={{...styles.menuItem, fontSize: 14, textAlign: 'left'}}>
                                {menuItem.name}
                            </Text>
                        </Col>
                        <Col size={3}>
                            <Text
                                style={{...styles.menuItem, fontSize: 16, textAlign: 'right', fontWeight: 'bold'}}>
                                ${menuItem.price?.toFixed(2)}
                            </Text>
                        </Col>
                    </Row>
                </Card>
            });
        } else {
            return <Text h4 style={{width: '100%', margin: 10}}>
                {/*{this.state.current_category.category}*/}
            </Text>
        }
    }

    updateCurrentCustomerState(key, value) {
        let current_order = this.state.current_order;
        current_order.customer[key] = value;
        this.stateStore.updateSingleState('current_order', current_order);
    }

    updateItemQuantity(item, qty = 1) {
        let current_order = this.state.current_order;

        let foundIndex = null;
        for (let i = 0; i < current_order.items.length; i++) {
            if (item.id === current_order.items[i].id) {
                foundIndex = i;
                break;
            }
        }

        if (foundIndex != null) {
            current_order.items[foundIndex].quantity = qty;
            if (qty <= 0) {
                current_order.items.splice(foundIndex, 1);
            }
        } else {
            if (qty !== 0) {
                item.quantity = qty;
                current_order.items.push(item);
            }
        }

        this.calculateCart(current_order);
        this.stateStore.updateState([{'key': 'current_order', 'value': current_order}]);
    }

    getQuantityInCart(item) {
        let qty = 0;
        let current_order = this.state.current_order;

        for (let i = 0; i < current_order.items.length; i++) {
            if (item.id === current_order.items[i].id) {
                qty = item.quantity;
                break;
            }
        }
        return qty;
    }

    scanItemToCart(upc_code) {
        //scan by UPC codes
        let foundItem = null;
        outer:for (let i = 0; i < this.state.full_menu.length; i++) {
            for (let j = 0; j < this.state.full_menu[i].items.length; j++) {
                let item = this.state.full_menu[i].items[j];
                //json maybe int
                if (item.unit_upc == upc_code) {
                    foundItem = item;
                    break outer;
                }
            }
        }

        if (foundItem) {
            this.updateItemQuantity(foundItem, this.getQuantityInCart(foundItem) + 1);
        }
    }

    isEmptyOrder() {
        return this.state.current_order.items.length === 0;
    }

    calculateCart(current_order) {
        current_order.sub_total = 0;
        current_order.items.forEach((value, index) => {
            current_order.sub_total += (value.price * value.quantity);
        });

        current_order.tax = this.state.selectedLocation.tax_rate * current_order.sub_total;
        current_order.total = current_order.tax + current_order.sub_total;

        if (current_order.tip && !isNaN(current_order.tip)) {
            current_order.total += parseFloat(current_order.tip);
        }
        if (current_order.fees && !isNaN(current_order.fees)) {
            current_order.total += parseFloat(current_order.fees);
        }
    }

    isCustomer() {
        return this.state.authType === 'CUSTOMER';
    }

    isBusiness() {
        return this.state.authType === 'BUSINESS';
    }

    aboutUs() {
        let location = this.state.selectedLocation;

        if (location) {
            return <View style={{
                flex: 1,
                flexDirection: "column",
                justifyContent: 'space-evenly',
                width: rightColWidth,
                height: windowHeight - 50,
                position: 'absolute',
                left: leftColWidth + centerColWidth,
                top: 50
            }}>
                <View style={{flex: 1}}>
                    <Text style={{textAlign: 'center', fontSize: 30, marginTop: 20}}>{location.name}</Text>
                    <Text style={{
                        textAlign: 'center',
                        fontSize: 20
                    }}>{`${location.address_1} ${location.address_2 ? location.address_2 : ''} ${location.city},${location.state},${location.zip}`}</Text>
                    <HTML source={{html: location.description}} contentWidth={300}/>
                </View>
            </View>
        }
    }

    rightColumnUI() {
        let location = this.state.selectedLocation;
        let searchCustomerForm = this.isBusiness() ? <View style={{
            // Try setting `flexDirection` to `"row"`.
            flexDirection: "row"
        }}>
            <Text style={{
                fontSize: 16,
                margin: 2,
                flex: 4,
                marginTop: 15,
                textAlign: 'center'
            }}>
                {this.state.current_order.id ?
                    `Order Id ${this.state.current_order.id},   Unique Id ${this.state.current_order.secondary_id}` : ''}
                {'\n'}
                {this.state.current_order.customer ?
                    `Customer Name: ${this.state.current_order.customer.name} -  Phone: ${this.state.current_order.customer.phone}` : this.state.i18n.t('noCustomerInOrder')}
            </Text>
            <TouchableOpacity style={{flex: 1}} onPress={() => {
                this.stateStore.updateSingleState('customerInfoModalVisible', true);
            }}>
                <Icon
                    reverse
                    name='user-plus'
                    type='feather'
                    color='#2288dc'
                />
            </TouchableOpacity>
        </View> : null;

        if (location && location.kiosk) {
            return <View style={{
                width: rightColWidth,
                height: windowHeight - 100,
                position: 'absolute',
                left: leftColWidth + centerColWidth,
                top: 50
            }}>
                {
                    searchCustomerForm
                }
                <ButtonGroup
                    onPress={(index) => {
                        let type = this.state.meta_data.order_types[index]
                        this.updateCurrentOrderState('type', type.name);
                    }}
                    selectedIndex={this.state.meta_data.order_types.length === 1 ? 0 :
                        this.state.meta_data.order_types.findIndex(type => type.name === this.state.current_order.type)}
                    buttons={this.state.meta_data.order_types.map(ot => this.state.i18n.t(ot.name))}
                    textStyle={{fontSize: 15}}
                    containerStyle={{height: 50}}
                />
                <ScrollView ref={ref => {
                    this.scrollView = ref
                }}
                            onContentSizeChange={() => this.scrollView.scrollToEnd({animated: true})}>
                    {
                        this.state.current_order.items.map(menuItem => {
                            return <TouchableOpacity key={menuItem.id} onPress={() => {
                                if (this.state.selectedLocation?.kiosk) {
                                    this.selectItem(menuItem);
                                }
                            }}>
                                <ListItem style={{width: '100%', borderWidth: 1, borderColor: LIGHT_GRAY}}>
                                    <Row>
                                        <Col size={10}>
                                            <Text style={{
                                                ...styles.orderName,
                                                fontSize: 12
                                            }}>{`x${menuItem.quantity}`}</Text>
                                        </Col>
                                        <Col size={60}>
                                            <Text
                                                style={styles.orderName}>{menuItem.name + ' - ' + menuItem.size} </Text>
                                        </Col>
                                        <Col size={30}>
                                            <Text
                                                style={styles.orderPrice}>${(menuItem.quantity * menuItem.price).toFixed(2)}</Text>
                                            <Text
                                                style={{
                                                    ...styles.orderPrice,
                                                    fontSize: 16,
                                                    textAlign: 'right'
                                                }}>${menuItem.price}/pc</Text>
                                        </Col>
                                    </Row>
                                </ListItem>
                            </TouchableOpacity>
                        })
                    }
                </ScrollView>
                <ListItem noIndent containerStyle={{height: 25}}>
                    <View
                        style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', height: 20}}
                    >
                        <Text style={styles.subTotal}>{this.state.i18n.t('subTotal')}</Text>
                        <Text
                            style={styles.subTotal}>${this.state.current_order.sub_total?.toFixed(2)}</Text>
                    </View>
                </ListItem>
                <ListItem noIndent containerStyle={{height: 25}}>
                    <View
                        style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', height: 15}}
                    >
                        <Text style={styles.subTotal}>{this.state.i18n.t('tax')}
                            ({this.state.selectedLocation.tax_rate})</Text>
                        <Text
                            style={styles.subTotal}>${this.state.current_order.tax?.toFixed(2)}</Text>
                    </View>
                </ListItem>
                <ListItem noIndent onPress={() => {
                    if (this.isBusiness()) {
                        this.stateStore.updateSingleState('paymentModalVisible', true);
                    }
                }} containerStyle={{height: 50}}>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={styles.total}>{this.state.i18n.t('total')}</Text>
                        <Text style={styles.total}>
                            <Icon
                                name={'wallet-outline'}
                                type='ionicon'
                                color={BLUE}
                                style={{display: !this.isBusiness() ? 'none' : 'inline'}}
                            />
                            &nbsp;
                            ${this.state.current_order.total?.toFixed(2)}
                        </Text>
                    </View>
                </ListItem>
                <ListItem noIndent containerStyle={{height: 30}}>
                    <Grid>
                        <Col size={2}>
                            <Button block light onPress={() => {
                                this.showDeletePrompt();
                            }} title={this.state.i18n.t('new')} titleStyle={styles.total} style={{}}/>
                        </Col>
                        {
                            this.printButton()
                        }
                        <Col size={this.isBusiness() ? 6 : 8}>
                            <Button block success style={{marginLeft: 10}} onPress={() => {
                                if (!this.isEmptyOrder()) {
                                    if (this.isBusiness()) {
                                        this.submitBusinessOrder();
                                    } else if (this.isCustomer()) {
                                        this.submitCustomerOrderRequest();
                                    }
                                }
                            }} title={this.state.i18n.t('checkout')} titleStyle={styles.total}/>
                        </Col>
                    </Grid>
                </ListItem>
            </View>
        } else {
            return this.aboutUs();
        }
    }

    searchCustomer(term) {
        if (term && term.length > 0) {
            this.loadingModal.showModal(true, this.state.i18n.t("searchCustomerOrder"));
            this.loadCustomer(term, () => {
                this.loadingModal.showModal(false, "");
            });
        }
    }

    searchCustomerOrderById(term) {
        if (!this.isBusiness()) {
            return;
        }
        if (this.state.current_order.items.length > 0) {
            alert(`${this.state.i18n.t('orderInProgress')}`);
            return;
        }
        if (term && term.length > 0) {
            this.loadingModal.showModal(true, this.state.i18n.t("searchCustomerOrder"));
            this.loadOrderById(term, () => {
                this.loadingModal.showModal(false, "");
            });
        }
    }

    customerDeliveryNoteInput() {
        //if (this.state.current_order.type === DELIVERY) {
        return <Input label={'Delivery instruction'}
                      value={this.state.current_order.customer.delivery_note}
                      onChangeText={(term) => {
                          this.updateCurrentCustomerState('delivery_note', term);
                      }} autoCompleteType={'off'} autoCorrect={false}
                      placeholder={'Record customer delivery instruction here'}/>
    }

    updateCurrentOrderState(key, value) {
        let current_order = this.state.current_order;
        current_order[key] = value;
        this.stateStore.updateSingleState('current_order', current_order);
    }

    updateCurrentOrderPaymentState(key, value) {
        let current_order = this.state.current_order;
        current_order[key] = value;

        this.calculateCart(current_order);
        this.stateStore.updateSingleState('current_order', current_order);
    }

    updateCurrentCustomerState(key, value) {
        let current_order = this.state.current_order;
        current_order.customer[key] = value;
        this.stateStore.updateSingleState('current_order', current_order);
    }

    showPrintPrompt() {
        this.stateStore.updateSingleState('printModalVisible', true);
    }

    showDeletePrompt() {
        if (Platform.OS === 'web') {
            let action = confirm(this.state.i18n.t('clearOrder'));
            if (action === true) {
                this.stateStore.updateState([{'key': 'current_order', 'value': blankOrder()}]);
            }
        } else {
            Alert.prompt(
                'Clear the current order?',
                'This cannot be undone',
                [
                    {
                        text: 'Cancel',
                        onPress: () => {
                        },
                        style: 'cancel',
                    },
                    {
                        text: 'Clear',
                        onPress: () => {
                            this.stateStore.updateState([{'key': 'current_order', 'value': blankOrder()}]);
                        },
                        style: 'destructive',
                    },
                ],
                'default',
            );
        }
    }

    submitBusinessOrder() {
        if (this.isEmptyOrder() || !this.state.username) {
            return;
        }

        this.loadingModal.showModal(true, "Saving Order");

        let current_order = this.state.current_order;

        current_order.status = PROCESSING;
        current_order.payment_method = CASH;

        this.stateStore.updateSingleState('current_order', this.state.current_order);

        let postBody = {
            'id': current_order.id,
            'secondary_id': current_order.secondary_id,
            'fk_location_id': this.state.selectedLocation.id,
            'type': current_order.type,
            'status': current_order.status,
            'items': current_order.items,
            'customer': current_order.customer,
            'special_note': current_order.special_note,
            'delivery_note': current_order.delivery_note,
            'table_number': current_order.table_number,
            'fees': current_order.fees,
            'tip': current_order.tip,
            'discount': current_order.discount,
            'sub_total': current_order.sub_total,
            'tax': current_order.tax,
            'grand_total': current_order.total,
            'payment_reference': current_order.payment_reference,
            'payment_method': current_order.payment_method,
            'payment_amount': current_order.payment_amount,
        };

        saveOrder(postBody, this.state.accessToken).then(response => {
            if (response.hasOwnProperty('id')) {
                current_order.id = response.id;
                this.stateStore.updateSingleState('current_order', current_order);
                alert(`${this.state.i18n.t('orderSubmitted')} #${response.id}`);
            } else {
                if (response.status === 403) {
                    alert(`${this.state.i18n.t('orderForbidden')}`);
                } else {
                    alert(`${this.state.i18n.t('orderError')}`);
                }
                console.log('Order error ' + JSON.stringify(response));
            }
        }).catch((error) => {
            console.error(error);
            alert(`${this.state.i18n.t('orderError')}`);
        }).finally(() => {
            this.loadingModal.showModal(false, "");
        });
    }

    submitCustomerOrderRequest() {
        if (this.isEmptyOrder() || !this.state.username || this.isBusiness()) {
            return;
        }

        if (this.isCustomer()) {
            console.log(`Customer checking out`);
            console.log(this.state.current_order.customer);

            if (this.state.selectedLocation.order_minimum > this.state.current_order.total) {
                alert(this.state.i18n.t('orderMinimum') + ' $' + this.state.selectedLocation.order_minimum);
                return;
            }
        }

        this.loadingModal.showModal(true, "Saving Order");

        let current_order = this.state.current_order;

        current_order.status = PROCESSING;

        this.stateStore.updateSingleState('current_order', this.state.current_order);

        let postBody = {
            'id': current_order.id,
            'secondary_id': current_order.secondary_id,
            'fk_location_id': this.state.selectedLocation.id,
            'type': current_order.type,
            'status': current_order.status,
            'items': current_order.items,
            'customer': current_order.customer,
            'special_note': current_order.special_note,
            'delivery_note': current_order.delivery_note,
            'table_number': current_order.table_number,
            'fees': current_order.fees,
            'tip': current_order.tip,
            'discount': current_order.discount,
            'sub_total': current_order.sub_total,
            'tax': current_order.tax,
            'grand_total': current_order.total,
            'payment_reference': current_order.payment_reference,
            'payment_method': current_order.payment_method,
            'payment_amount': current_order.payment_amount,
        };

        saveOrder(postBody, this.state.accessToken).then(response => {
            if (response.hasOwnProperty('id')) {
                current_order.id = response.id;
                alert(`${this.state.i18n.t('orderSubmitted')} #${response.id}`);

                sendEmailForOrder(current_order.id);
            } else {
                if (response.status === 403) {
                    alert(`${this.state.i18n.t('orderForbidden')}`);
                } else {
                    alert(`${this.state.i18n.t('orderError')}`);
                }
                console.log('Order error ' + JSON.stringify(response));
            }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            this.loadingModal.showModal(false, "");
        });
    }

    selectItem(item) {
        let cartItems = this.state.current_order.items;
        let qty = item.case_count;
        cartItems.forEach(ci => {
            if (ci.id === item.id) {
                qty = item.quantity ? item.quantity : qty;
            }
        })

        this.stateStore.updateState([{key: 'itemDetailModalVisible', value: true},
            {key: 'selectedItem', value: item},
            {key: 'selectedQuantity', value: qty}]);
    }

    adjustQuantity() {
        if (!this.isBusiness()) {
            return;
        }

        let newQty = prompt(this.state.i18n.t("adjustQty"), this.state.selectedQuantity);

        if (newQty != null) {
            try {
                newQty = parseInt(newQty);
                if (newQty !== this.state.selectedQuantity) {
                    this.stateStore.updateSingleState('selectedQuantity', newQty);
                }
            } catch (e) {

            }
        }
    }

    adjustPrice() {
        if (!this.isBusiness()) {
            return;
        }
        let newPrice = prompt(this.state.i18n.t("adjustPrice"), this.state.selectedItem.price);

        if (newPrice != null) {
            try {
                newPrice = parseFloat(newPrice);
                if (newPrice != this.state.selectedItem.price) {
                    let newItem = this.state.selectedItem;
                    newItem.price = newPrice;
                    this.stateStore.updateSingleState('selectedItem', newItem);
                }
            } catch (e) {
            }
        }
    }

    selectedItemImage() {
        if (this.state.selectedItem) {
            let menuItem = this.state.selectedItem;
            return <Grid>
                <Col>
                    <Card containerStyle={{
                        margin: 0,
                        width: 400,
                        borderBottomWidth: 0,
                        borderBottomColor: 'white'
                    }} style={{backgroundColor: 'white'}}>
                        <Card.Image source={{uri: menuItem.image}} style={{
                            height: 400
                        }}>
                            <Text style={{display: 'none'}}>
                                {menuItem.case_upc}
                            </Text>
                            <Row>
                                <Col>
                                    <Text style={{
                                        textAlign: 'left',
                                        color: RED,
                                        fontWeight: 'bold',
                                        fontSize: 15
                                    }}>
                                        {
                                            menuItem.fk_catalog_status_id ? this.state.meta_data.catalog_status.find(cs => cs.id === menuItem.fk_catalog_status_id).name : ''
                                        }
                                    </Text>
                                </Col>
                                <Col>
                                    <Text style={{
                                        textAlign: 'right',
                                        fontSize: 15
                                    }}>
                                        {menuItem.case_count} {this.state.i18n.t('pcscs')}
                                    </Text>
                                </Col>
                            </Row>
                            <Row style={{
                                marginTop: 'auto',
                            }}>
                                <Col>
                                    <Text style={{
                                        textAlign: 'center',
                                        fontSize: 35,
                                        marginTop: '0',
                                        width: '200%',
                                        transform: [{rotate: '-45deg'}],
                                        color: RED
                                    }}>
                                        {this.state.selectedLocation?.name}
                                    </Text>
                                    <Text style={{
                                        textAlign: 'left',
                                        fontSize: 18,
                                        marginTop: 'auto',
                                    }}>
                                        {menuItem.item_number}
                                    </Text>
                                </Col>
                                <Col>
                                    <Text style={{
                                        textAlign: 'right',
                                        fontSize: 18,
                                        marginTop: 'auto',
                                    }}>
                                        {menuItem.size}
                                    </Text>
                                </Col>
                            </Row>
                        </Card.Image>
                        <Row style={{marginTop: 10}}>
                            <Col size={7}>
                                <Text style={{...styles.menuItem, fontSize: 20, textAlign: 'left'}}>
                                    {menuItem.name}
                                </Text>
                            </Col>
                            <Col size={3}>
                                <Text
                                    style={{...styles.menuItem, fontSize: 22, textAlign: 'right', fontWeight: 'bold'}}>
                                    ${menuItem.price.toFixed(2)}
                                </Text>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col>
                    <Text style={{
                        fontSize: 20,
                        marginTop: 20
                    }}>{`${this.state.i18n.t('unitPrice')} $${menuItem.price.toFixed(2)}   |   ${this.state.i18n.t('lotPrice')} $${(menuItem.price * menuItem.case_count).toFixed(2)}`}</Text>
                    <Text style={{
                        fontSize: 16,
                        marginTop: 5
                    }}>{this.state.i18n.t('sellByLot')} {menuItem.case_count}</Text>
                    <Text style={{fontSize: 24, marginTop: 30}}>{menuItem.name}</Text>
                    <Text style={{
                        fontSize: 18,
                        marginTop: 20
                    }}>{this.state.i18n.t('itemNumber')} {menuItem.item_number}</Text>
                    <Text style={{fontSize: 18, marginTop: 10}}>{this.state.i18n.t('size')} {menuItem.size}</Text>

                    <Text style={{fontSize: 16, marginTop: 20}}><Text style={{fontWeight: 'bold'}}>Disclaimer</Text>:
                        * Image may not always be the current item design. Some items may also have alternative editions
                        and names that are synonymous. If the item design or name is a great deal to you, check with us
                        first before purchasing.
                    </Text>

                    <Row style={{marginTop: 20}}>
                        <Col size={5}>
                            <Text style={{fontSize: 20, marginTop: 15, borderBottomWidth: 1}} onPress={() => {
                                this.adjustQuantity()
                            }}>
                                {this.state.i18n.t('totalInCart')} {this.state.selectedQuantity}
                                &nbsp;&nbsp;&nbsp;
                                <Icon name='pencil-square-o' type='font-awesome' color='#517fa4'
                                      style={{display: !this.isBusiness() ? 'none' : 'inline'}}/>
                            </Text>
                        </Col>
                        <Col size={5}>
                            <ButtonGroup
                                buttons={["-", "+"]}
                                textStyle={{fontSize: 25}}
                                onPress={(selectedIndex) => {
                                    if (selectedIndex === 0 && this.state.selectedQuantity > 0) {
                                        let newQty = this.state.selectedQuantity - this.state.selectedItem.case_count;
                                        this.stateStore.updateSingleState('selectedQuantity', newQty);
                                    } else if (selectedIndex === 1) {
                                        let newQty = this.state.selectedQuantity + this.state.selectedItem.case_count;
                                        this.stateStore.updateSingleState('selectedQuantity', newQty);
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Text style={{
                        textAlign: 'left',
                        fontSize: 25,
                        width: '90%',
                        marginLeft: '5%',
                        marginBottom: 20
                    }} onPress={() => {
                        this.adjustPrice()
                    }}>{this.state.selectedQuantity} x
                        ${this.state.selectedItem.price} =
                        ${(this.state.selectedItem.price * this.state.selectedQuantity).toFixed(2)}
                        <Icon name='pencil-square-o' type='font-awesome' color='#517fa4'
                              style={{display: !this.isBusiness() ? 'none' : 'inline'}}/>
                    </Text>
                    <Row>
                        <Col size={2}>
                            <Button
                                disabled={this.state.selectedItem.fk_catalog_status_id === SOLD_OUT}
                                title={this.state.i18n.t('empty')}
                                buttonStyle={{width: '90%', marginLeft: '5%', height: 50, backgroundColor: RED}}
                                onPress={() => {
                                    this.updateItemQuantity(this.state.selectedItem, 0);
                                    this.stateStore.updateSingleState('itemDetailModalVisible', false);
                                }}
                            />
                        </Col>
                        <Col size={8}>
                            <Button
                                disabled={this.state.selectedItem.fk_catalog_status_id === SOLD_OUT}
                                title={this.state.selectedItem.fk_catalog_status_id === SOLD_OUT ? this.state.i18n.t('soldOut') : this.state.i18n.t('updateCart')}
                                buttonStyle={{width: '90%', marginLeft: '5%', height: 50}}
                                onPress={() => {
                                    this.updateItemQuantity(this.state.selectedItem, this.state.selectedQuantity);
                                    this.stateStore.updateSingleState('itemDetailModalVisible', false);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Grid>
        }
        return null;
    }

    closePrint() {
        document.body.removeChild(this.__container__);
    }

    setPrint() {
        this.contentWindow.__container__ = this;
        this.contentWindow.onbeforeunload = this.closePrint;
        this.contentWindow.onafterprint = this.closePrint;
        this.contentWindow.focus(); // Required for IE
        this.contentWindow.print();
    }

    printPage(sURL) {
        let oHideFrame = document.createElement("iframe");
        oHideFrame.onload = this.setPrint;
        oHideFrame.style.position = "fixed";
        oHideFrame.style.right = "0";
        oHideFrame.style.bottom = "0";
        oHideFrame.style.width = "0";
        oHideFrame.style.height = "0";
        oHideFrame.style.border = "0";
        oHideFrame.src = sURL;
        document.body.appendChild(oHideFrame);
    }

    printButton() {
        if (!this.isBusiness()) {
            return;
        }
        return <Col size={2}>
            <Button block light onPress={() => {
                this.showPrintPrompt();
            }} disabled={this.state.current_order.id == null}
                    icon={<Icon
                        name='printer' color={'#ffffff'} type={'feather'}/>} titleStyle={styles.total}
                    style={{marginLeft: 10}}/>
        </Col>
    }

    printFullReceipt() {
        //use primary Id
        if (this.state.current_order.id == null) {
            alert(this.state.i18n.t('saveOrderFirst'));
            return;
        }
        let customerId = this.state.current_order.fk_customer_id ? this.state.current_order.fk_customer_id
            : this.state.current_order.customer.id;
        getServerUrl().then(serverUrl => {
            let receiptUrl = `${serverUrl}/order/invoice/${customerId}/${this.state.current_order.secondary_id}`;

            this.printPage(receiptUrl);
        });
    }

    printReceipt() {
        //use primary Id
        if (this.state.current_order.id == null) {
            alert(this.state.i18n.t('saveOrderFirst'));
            return;
        }
        let customerId = this.state.current_order.fk_customer_id ? this.state.current_order.fk_customer_id
            : this.state.current_order.customer.id;
        getServerUrl().then(serverUrl => {
            let receiptUrl = `${serverUrl}/order/invoice_thermal/${customerId}/${this.state.current_order.secondary_id}/receipt`;

            this.printPage(receiptUrl);
        });
    }

    printInvoice() {
        //use primary Id
        if (this.state.current_order.id == null) {
            alert(this.state.i18n.t('saveOrderFirst'));
            return;
        }
        let customerId = this.state.current_order.fk_customer_id ? this.state.current_order.fk_customer_id
            : this.state.current_order.customer.id;
        getServerUrl().then(serverUrl => {
            let receiptUrl = `${serverUrl}/order/invoice_thermal/${customerId}/${this.state.current_order.secondary_id}/invoice`;

            this.printPage(receiptUrl);
        });
    }

    printOrderSlip() {
        if (this.state.current_order.id == null) {
            alert(this.state.i18n.t('saveOrderFirst'));
            return;
        }
        let customerId = this.state.current_order.fk_customer_id ? this.state.current_order.fk_customer_id
            : this.state.current_order.customer.id;
        getServerUrl().then(serverUrl => {
            let receiptUrl = `${serverUrl}/order/invoice_thermal/${customerId}/${this.state.current_order.secondary_id}/order_slip`;

            this.printPage(receiptUrl);
        });
    }

    //business only features
    showScanButton() {
        if (this.isBusiness()) {
            return <TouchableOpacity style={{flex: 1}} onPress={() => {
                this.barcodeInput.focus();
                this.stateStore.updateSingleState('scanMode', true);
            }}>
                <Icon
                    reverse
                    name='barcode-scan'
                    type='material-community'
                    color='#2288dc'
                />
            </TouchableOpacity>
        }
    }

    render() {
        return (
            <View style={{
                height: windowHeight - 50,
                maxHeight: windowHeight - 50,
                width: windowWidth,
                maxWidth: windowWidth,
                backgroundColor: LIGHT_GRAY
            }}>
                <Header containerStyle={{height: 50}}
                    // leftComponent={<Button
                    //     icon={
                    //         <Icon
                    //             name="language"
                    //             color="white"
                    //             type="ionicon"
                    //         />
                    //     }
                    //     title={this.state.i18n.t('language')}
                    //     onPress={() => {
                    //         getLocale().then(r => {
                    //             let newLocale = r === 'en' ? 'zh' : 'en';
                    //             storeData(LOCALE, newLocale).then(() => {
                    //                 this.stateStore.updateSingleState('il8n', translator(newLocale));
                    //             });
                    //         });
                    //     }}
                    // />}
                        leftComponent={<Button
                            icon={
                                <Icon
                                    name="log-out"
                                    color="white"
                                    type="feather"
                                />
                            }
                            title={" " + this.state.i18n.t('logout')}
                            onPress={() => {
                                invalidate_auth(this.state.accessToken).then(r => {
                                    //this.props.navigation.navigate('Login');

                                    if (Platform.OS === 'web') {
                                        location.reload();
                                    }
                                });
                            }}
                        />}
                        centerComponent={{
                            text: this.state.selectedLocation?.name,
                            style: {color: '#fff', fontSize: 28, lineHeight: 50},
                        }}
                        rightComponent={this.isBusiness() ? <Button
                            icon={
                                <Icon
                                    name="search"
                                    color="white"
                                    type="feather"
                                />
                            }
                            title={" " + this.state.i18n.t('searchCustomerOrder')}
                            onPress={() => {
                                let input = prompt(this.state.i18n.t("searchCustomerOrder"));
                                if (input) {
                                    this.searchCustomerOrderById(input);
                                }
                            }}
                        /> : <Text style={{
                            color: '#fff',
                            marginTop: 7,
                            fontSize: 20,
                            lineHeight: 30
                        }}>{this.state.username}</Text>}
                />
                <View style={{width: leftColWidth, height: windowHeight - 100, position: 'absolute', left: 0, top: 50}}>
                    <ScrollView>
                        <Text style={{textAlign: 'center', marginTop: 10, fontSize: 18}}>
                            Categories
                            <Button
                                type="clear"
                                buttonStyle={{borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0}}
                                title={'Reload'}
                                titleStyle={{fontSize: 18, color: 'black'}}
                                onPress={() => {
                                    this.reloadCatalog();
                                }}
                            />
                        </Text>
                        {this.generateCategories()}
                    </ScrollView>
                </View>
                <View style={{
                    width: centerColWidth,
                    height: windowHeight - 100,
                    position: 'absolute',
                    left: leftColWidth,
                    top: 50
                }}>
                    <View style={{
                        // Try setting `flexDirection` to `"row"`.
                        flexDirection: "row"
                    }}>
                        <View style={{flex: 9}}>
                            <SearchBar
                                lightTheme
                                placeholder={this.state.i18n.t('typeHereToSearch')}
                                inputStyle={{
                                    fontSize: 25,
                                    color: 'black'
                                }}
                                showLoading={false}
                                clearIcon={{
                                    name: 'close',
                                    type: ' FontAwesome'
                                }}
                                value={this.state.search_term}
                                onClear={() => {
                                    this.clearSearchItems();
                                }}
                                autoCompleteType={'off'} autoCorrect={false}
                                blurOnSubmit={false}
                                onChangeText={(term) => {
                                    if (term.length > 0) {
                                        this.stateStore.updateSingleState('search_term', term);
                                        this.searchMenu(term);
                                    } else if (term.length === 0) {
                                        this.stateStore.updateSingleState('search_term', term);
                                        this.clearSearchItems();
                                    }
                                }}
                            />
                        </View>
                        {
                            this.showScanButton()
                        }
                    </View>
                    <ScrollView>
                        <View style={{
                            flex: 1,
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                        }}>
                            {this.generateFlexMenuItems()}
                        </View>
                    </ScrollView>
                </View>
                {this.rightColumnUI()}

                <LoadingModal ref={child => {
                    this.loadingModal = child;
                }} showLoading={true}/>

                <Modal
                    style={{
                        position: 'absolute',
                        left: windowWidth - rightColWidth,
                        width: rightColWidth,
                        height: windowHeight,
                        backgroundColor: 'white',
                        zIndex: 10,
                        display: this.state.customerInfoModalVisible ? 'block' : 'none',
                        borderColor: '#00000000'
                    }}
                    animationType="slide"
                    transparent={true}
                    visible={this.state.customerInfoModalVisible}
                >
                    <View style={{...styles.centeredView}}>
                        <View style={{...styles.modalView}}>
                            <Header
                                centerComponent={{text: 'Customer', style: {color: '#fff', fontSize: 30}}}
                                rightComponent={{
                                    icon: 'close', color: '#fff', onPress: () => {
                                        this.stateStore.updateSingleState('customerInfoModalVisible', false)
                                    }
                                }}
                            />
                            <View style={{
                                flexDirection: "row",
                                marginTop: 10,
                                marginBottom: 10,
                            }}>
                                <View style={{flex: 4}}>
                                    <SearchBar
                                        lightTheme
                                        placeholder={this.state.i18n.t('typeHereToSearch')}
                                        inputStyle={{
                                            fontSize: 20,
                                            color: 'black'
                                        }}
                                        showLoading={false}
                                        clearIcon={{
                                            name: 'close',
                                            type: ' FontAwesome'
                                        }}
                                        value={this.state.searchCustomerInput}
                                        onChangeText={(term) => {
                                            this.stateStore.updateSingleState('searchCustomerInput', term);
                                        }}
                                        onClear={() => {
                                        }}
                                        autoCompleteType={'off'} autoCorrect={false}
                                    />
                                </View>
                                <TouchableOpacity style={{flex: 1}} onPress={() => {
                                    this.searchCustomer(this.state.searchCustomerInput);
                                }}>
                                    <Icon
                                        reverse
                                        name='search'
                                        type='feather'
                                        color='#2288dc'
                                    />
                                </TouchableOpacity>
                            </View>
                            <Input label={this.state.i18n.t('id')}
                                   value={this.state.current_order.customer.id}
                                   editable={false}/>

                            <Input label={this.state.i18n.t('name')}
                                   value={this.state.current_order.customer.name}
                                   onChangeText={(term) => {
                                       this.updateCurrentCustomerState('name', term);
                                   }} autoCompleteType={'off'} autoCorrect={false}
                                   placeholder={this.state.i18n.t('namePlaceholder')}/>

                            <Input label={this.state.i18n.t('phoneNumber')}
                                   value={this.state.current_order.customer.phone}
                                   keyboardType="phone-pad"
                                   onChangeText={(term) => {
                                       this.updateCurrentCustomerState('phone', term);
                                   }} autoCompleteType={'off'} autoCorrect={false}
                                   placeholder={'000-000-0000'}/>

                            {/* customer username is email address */}
                            <Input label={this.state.i18n.t('email')}
                                   value={this.state.current_order.customer.username}
                                   onChangeText={(term) => {
                                       this.updateCurrentCustomerState('username', term);
                                   }} autoCompleteType={'off'} autoCorrect={false}
                                   placeholder={this.state.i18n.t('email')}/>

                            <Input label={this.state.i18n.t('address')}
                                   value={this.state.current_order.customer.address_1}
                                   onChangeText={(term) => {
                                       this.updateCurrentCustomerState('address_1', term);
                                   }} autoCompleteType={'off'} autoCorrect={false}
                                   placeholder={this.state.i18n.t('addressPlaceholder')}/>

                            <Input label={this.state.i18n.t('addressTwo')}
                                   value={this.state.current_order.customer.address_2}
                                   onChangeText={(term) => {
                                       this.updateCurrentCustomerState('address_2', term);
                                   }} autoCompleteType={'off'} autoCorrect={false}
                                   placeholder={this.state.i18n.t('addressTwoPlaceholder')}/>

                            <Input label={this.state.i18n.t('city')}
                                   value={this.state.current_order.customer.city}
                                   onChangeText={(term) => {
                                       this.updateCurrentCustomerState('city', term);
                                   }} autoCompleteType={'off'} autoCorrect={false}
                                   placeholder={this.state.i18n.t('cityPlaceholder')}/>

                            <Input label={this.state.i18n.t('state')}
                                   value={this.state.current_order.customer.state}
                                   onChangeText={(term) => {
                                       this.updateCurrentCustomerState('state', term);
                                   }} autoCompleteType={'off'} autoCorrect={false}
                                   placeholder={this.state.i18n.t('statePlaceholder')}/>

                            <Input label={this.state.i18n.t('zipcode')}
                                   value={this.state.current_order.customer.zipcode}
                                   onChangeText={(term) => {
                                       this.updateCurrentCustomerState('zipcode', term);
                                   }} autoCompleteType={'off'} autoCorrect={false}
                                   placeholder={this.state.i18n.t('zipcodePlaceholder')}/>
                        </View>
                    </View>
                </Modal>

                <Modal
                    style={{
                        position: 'absolute',
                        left: windowWidth - rightColWidth,
                        width: rightColWidth,
                        height: windowHeight,
                        backgroundColor: 'white',
                        zIndex: 10,
                        display: this.state.paymentModalVisible ? 'block' : 'none',
                        borderColor: '#00000000'
                    }}
                    animationType="slide"
                    transparent={true}
                    visible={this.state.paymentModalVisible}
                >
                    <View style={{...styles.centeredView}}>
                        <View style={{...styles.modalView}}>
                            <Header
                                centerComponent={{text: 'Payment', style: {color: '#fff', fontSize: 30}}}
                                rightComponent={{
                                    icon: 'close', color: '#fff', onPress: () => {
                                        this.stateStore.updateSingleState('paymentModalVisible', false)
                                    }
                                }}
                            />
                            <Input inputContainerStyle={{
                                width: '80%',
                                marginLeft: '10%',
                                borderWidth: 1,
                                borderColor: GRAY,
                                height: 50
                            }}
                                   label={this.state.i18n.t('customerPaid')}
                                   labelStyle={styles.whiteLabel}
                                   inputStyle={{
                                       color: 'black',
                                       textAlign: 'center',
                                       fontSize: 30,
                                       lineHeight: 50,
                                       width: '80%'
                                   }}
                                   leftIcon={{type: 'ionicon', name: 'logo-usd', iconStyle: styles.inputIcon}}
                                   value={this.state.current_order.payment_amount} autoCapitalize={'none'}
                                   autoCompleteType={'off'}
                                   autoCorrect={false} secureTextEntry={false} onChangeText={(term) => {
                                this.updateCurrentOrderPaymentState('payment_amount', term);
                            }}/>

                            <Input inputContainerStyle={{
                                width: '80%',
                                marginLeft: '10%',
                                borderWidth: 1,
                                borderColor: GRAY,
                                height: 50
                            }}
                                   label={this.state.i18n.t('tipAmount')}
                                   labelStyle={styles.whiteLabel}
                                   inputStyle={{
                                       color: 'black',
                                       textAlign: 'center',
                                       fontSize: 30,
                                       lineHeight: 50,
                                       width: '80%'
                                   }}
                                   leftIcon={{type: 'ionicon', name: 'logo-usd', iconStyle: styles.inputIcon}}
                                   value={this.state.current_order.tip} autoCapitalize={'none'}
                                   autoCompleteType={'off'}
                                   autoCorrect={false} secureTextEntry={false} onChangeText={(term) => {
                                this.updateCurrentOrderPaymentState('tip', term);
                            }}/>

                            <Input inputContainerStyle={{
                                width: '80%',
                                marginLeft: '10%',
                                borderWidth: 1,
                                borderColor: GRAY,
                                height: 50
                            }}
                                   label={this.state.i18n.t('extraFees')}
                                   labelStyle={styles.whiteLabel}
                                   inputStyle={{
                                       color: 'black',
                                       textAlign: 'center',
                                       fontSize: 30,
                                       lineHeight: 50,
                                       width: '80%'
                                   }}
                                   leftIcon={{type: 'ionicon', name: 'logo-usd', iconStyle: styles.inputIcon}}
                                   value={this.state.current_order.fees} autoCapitalize={'none'}
                                   autoCompleteType={'off'}
                                   autoCorrect={false} secureTextEntry={false} onChangeText={(term) => {
                                this.updateCurrentOrderPaymentState('fees', term);
                            }}/>

                            <View style={{
                                width: '80%',
                                marginLeft: '10%',
                            }}>
                                <ListItem noIndent>
                                    <View
                                        style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}
                                    >
                                        <Text style={styles.total}>{this.state.i18n.t("total")}</Text>
                                        <Text
                                            style={styles.total}>${this.state.current_order.total.toFixed(2)}</Text>
                                    </View>
                                </ListItem>
                                <ListItem noIndent>
                                    <View
                                        style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}
                                    >
                                        <Text style={styles.total}>{this.state.i18n.t("change")}</Text>
                                        <Text
                                            style={styles.total}>${(this.state.current_order.payment_amount === 0 ?
                                            0 : this.state.current_order.payment_amount - this.state.current_order.total).toFixed(2)}</Text>
                                    </View>
                                </ListItem>
                            </View>
                        </View>
                    </View>
                </Modal>

                <Modal
                    style={{
                        position: 'absolute',
                        left: (windowWidth - 816) / 2,
                        top: 75,
                        width: 816,
                        height: 616,
                        backgroundColor: 'white',
                        zIndex: 10,
                        display: this.state.itemDetailModalVisible ? 'block' : 'none',
                        borderColor: GRAY,
                    }}
                    animationType="slide"
                    transparent={true}
                    visible={this.state.itemDetailModalVisible}
                >
                    <View style={{...styles.centeredView}}>
                        <View style={{...styles.modalView}}>
                            <Header
                                centerComponent={{
                                    text: this.state.i18n.t('itemDetail'),
                                    style: {color: '#fff', fontSize: 30}
                                }}
                                rightComponent={{
                                    icon: 'close', color: '#fff', onPress: () => {
                                        this.stateStore.updateSingleState('itemDetailModalVisible', false)
                                    }
                                }}
                            />
                            {this.selectedItemImage()}
                        </View>
                    </View>
                </Modal>

                <Modal
                    style={{
                        position: 'absolute',
                        width: windowWidth,
                        height: windowHeight,
                        backgroundColor: TRANSLUCENT,
                        zIndex: 10,
                        display: this.state.scanMode ? 'block' : 'none',
                        borderColor: LIGHT_GRAY,
                    }}
                    animationType="slide"
                    transparent={true}
                    visible={this.state.scanMode}
                >
                    <View style={{...styles.centeredView}}>
                        <View style={{...styles.modalView}}>
                            <Header
                                centerComponent={{
                                    text: this.state.i18n.t('scanMode'),
                                    style: {color: '#fff', fontSize: 30}
                                }}
                                rightComponent={{
                                    icon: 'close', color: '#fff', onPress: () => {
                                        this.stateStore.updateSingleState('scanMode', false)
                                    }
                                }}
                            />
                            <Icon
                                containerStyle={{
                                    marginTop: 200
                                }}
                                size={230}
                                name='barcode-scan'
                                type='material-community'
                                color='#2288dc'
                            />
                            <TextInput
                                style={{
                                    width: '30%',
                                    height: 50,
                                    marginLeft: '35%',
                                    textAlign: 'center',
                                    fontSize: 30
                                }}
                                value={this.state.barcodeTerm}
                                blurOnSubmit={false}
                                showSoftInputOnFocus={false}
                                placeholder={this.state.i18n.t('scanText')}
                                placeholderTextColor={RED}
                                ref={(barcodeInput) => {
                                    this.barcodeInput = barcodeInput
                                }}
                                onBlur={(event) => {
                                    this.stateStore.updateSingleState('scanMode', false);
                                }}
                                onSubmitEditing={(event) => {
                                    if (event.nativeEvent.text) {
                                        let upc = event.nativeEvent.text;
                                        this.barcodeInput.setNativeProps({text: ''});

                                        setTimeout(() => {
                                            this.scanItemToCart(upc);
                                        }, 200)
                                    }
                                }}
                            />
                        </View>
                    </View>
                </Modal>

                <Modal
                    style={{
                        position: 'absolute',
                        left: windowWidth - rightColWidth,
                        width: rightColWidth,
                        height: windowHeight,
                        backgroundColor: 'white',
                        zIndex: 10,
                        display: this.state.printModalVisible ? 'block' : 'none',
                        borderColor: '#00000000'
                    }}
                    animationType="slide"
                    transparent={true}
                    visible={this.state.printModalVisible}
                >
                    <View style={{...styles.centeredView}}>
                        <View style={{...styles.modalView}}>
                            <Header
                                centerComponent={{text: 'Print', style: {color: '#fff', fontSize: 30}}}
                                rightComponent={{
                                    icon: 'close', color: '#fff', onPress: () => {
                                        this.stateStore.updateSingleState('printModalVisible', false)
                                    }
                                }}
                            />
                            <Button block light onPress={() => {
                                this.printOrderSlip();
                            }} title={this.state.i18n.t('printOrder')} titleStyle={styles.total} style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginTop: 20,
                                marginBottom: 20,
                            }}/>

                            <Button block light onPress={() => {
                                this.printInvoice();
                            }} title={this.state.i18n.t('printInvoice')} titleStyle={styles.total} style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginTop: 20,
                                marginBottom: 20,
                            }}/>

                            <Button block light onPress={() => {
                                this.printReceipt();
                            }} title={this.state.i18n.t('printReceipt')} titleStyle={styles.total} style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginTop: 20,
                                marginBottom: 20,
                            }}/>

                            <Button block light onPress={() => {
                                this.printFullReceipt();
                            }} title={this.state.i18n.t('printFullReceipt')} titleStyle={styles.total} style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginTop: 20,
                                marginBottom: 20,
                            }}/>
                        </View>
                    </View>
                </Modal>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        width: windowWidth,
        height: windowHeight,
    },
    headerText: {
        fontSize: 30,
        fontFamily: defaultFont,
    },
    container: {
        paddingTop: 50,
    },
    categoryImage: {
        height: 125,
        width: '100%',
        flex: 1,
    },
    categoryTitle: {
        width: '100%',
        lineHeight: 20,
        fontSize: 20,
        marginTop: 10,
        marginLeft: 0,
        fontFamily: defaultFont,
    },
    menuItem: {
        lineHeight: 20,
        fontSize: 20,
        width: '100%',
        fontWeight: 'bold',
        fontFamily: defaultFont,
    },
    menuPrice: {
        lineHeight: 20,
        fontSize: 20,
        width: '100%',
        textAlign: 'right',
        fontFamily: defaultFont,
    },
    subTotal: {
        fontSize: 20,
        textAlign: 'right',
        fontFamily: defaultFont,
    },
    total: {
        fontSize: 22,
        fontFamily: defaultFont,
        fontWeight: 'bold',
        textAlign: 'right',
        lineHeight: 25,
    },
    orderName: {
        lineHeight: 20,
        fontSize: 18,
        width: '100%',
        fontFamily: defaultFont,
    },
    orderPrice: {
        lineHeight: 20,
        fontSize: 18,
        width: '100%',
        textAlign: 'right',
        fontFamily: defaultFont,
    },
    roundedInput: {
        borderWidth: 1,
        borderColor: GRAY,
    },
    whiteLabel: {
        fontSize: 20,
        color: GRAY,
        marginLeft: '10%',
        marginTop: 10,
        marginBottom: 10
    },
    backTextWhite: {
        color: '#FFF',
    },
    rowFront: {
        alignItems: 'center',
        backgroundColor: '#CCC',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        justifyContent: 'center',
        height: 50,
    },
    rowBack: {
        alignItems: 'center',
        backgroundColor: '#DDD',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
    },
    backRightBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: 75,
    },
    backRightBtnLeft: {
        backgroundColor: 'blue',
        right: 75,
    },
    backRightBtnRight: {
        backgroundColor: 'red',
        right: 0,
    },
});
